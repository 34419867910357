import React from "react";


const ParagraphText = ({text}) => {
  return (
    <>
      {text.split('\n').map((paragraph, i) => <p key={i}>{paragraph}</p>)}
    </>
  )
}

export {ParagraphText}

const InTextAliasList = ({aliasList}) => {
  if (!aliasList || aliasList.length <= 0){
    return ""
  }

  const displayedNames = aliasList.slice(0,3).map((alias, i) => (<b key={i}>{alias}@</b>))
  const missingNames = aliasList.length - displayedNames.length
  const postfix  = missingNames > 0 ? `${missingNames} more` : null
  const namesBeforeTheAnd = missingNames > 0 ? displayedNames : displayedNames.slice(0, -1)
  const afterTheAnd = missingNames > 0 ? postfix : displayedNames.slice(-1)[0]
  const andJoiner = namesBeforeTheAnd.length > 1 ? ", and " : " and "

  return <>{namesBeforeTheAnd.reduce((accum, next) => accum === null ? [next] : [...accum, ', ', next], null)}
     {namesBeforeTheAnd.length > 0 ? andJoiner : null}{afterTheAnd}
  </>
}

const AliasList = ({aliasList}) => {
  if (!aliasList || aliasList.length <= 0){
    return <div>-</div>
  }

  const shortened = aliasList.length > 8 ? aliasList.slice(0, 8) : aliasList
  return (
    <div>
      <ul className="plain-compact">
        {shortened.map((alias, i) => <li key={alias}>{alias}@</li>)}
        {shortened.length !== aliasList.length ? <li>(+ {aliasList.length - shortened.length} more)</li> : null}
      </ul>
    </div>
  )
}

const DenominationList = ({denominations}) => {
  if (!denominations || denominations.length <= 0){
    return <div>-</div>
  }

  return (
    <div>
      <ul className="plain-compact">
        {denominations.map((denomination, i) => <li key={i}>{denomination.quantity}x ${denomination.value}</li>)}
      </ul>
    </div>
  )
}

export {DenominationList, AliasList, InTextAliasList}