import React from "react";

import {Button, Form} from '@amzn/awsui-components-react';
import {API, graphqlOperation} from "aws-amplify";
import {withRouter} from "react-router-dom";
import {createPartner, updatePartner} from "../../graphql/portfolioManagerAccess";
import PartnerDetailsPanel from "./PartnerDetailsPanel";


const initialFormState = {
  name: '',
  partnerLeaderAlias: '',
  partnerSecondaryAlias: '',
  allocatedBudget: null
}


class PartnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: this.props.initialState || initialFormState,
      formErrorText: "",
      hasSubmitted: false,
      submitLoading: false,
      formValid: !!this.props.initialState
    }

    this.submitCallback = this.submitCallback.bind(this)
    this.saveCallback = this.saveCallback.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.initialState !== this.props.initialState) {
      this.setState({formState: this.props.initialState})
    }
  }

  submitCallback() {
    this.setState({hasSubmitted: true})
    if (this.state.formValid) {
      this.setState({submitLoading: true})
      const formState = this.state.formState
      formState.consumedBudget = 0

      API.graphql(graphqlOperation(createPartner, {input: formState})).then((result) => {
        this.setState({submitLoading: false})
        this.props.history.push(`/Dashboard`)
      }).catch((error) => {
        console.log("Error submitting form: ", error)
        let errString = error.errors.map(er => er.errorType + ": " + er.message).join("; \n")
        this.setState({formErrorText: errString, submitLoading: false})
      })
    }
  }

  saveCallback() {
    this.setState({hasSubmitted: true, formErrorText: ''})
    if (this.state.formValid) {
      this.setState({submitLoading: true})
      const formState = this.state.formState
      formState.id = this.props.initialState.id

      API.graphql(graphqlOperation(updatePartner, {input: formState})).then((result) => {
        this.setState({submitLoading: false})
        this.props.history.push(`/Dashboard`)
      }).catch((error) => {
        console.log("Error submitting form: ", error)
        let errString = error.errors.map(er => er.errorType + ": " + er.message).join("; \n")
        this.setState({formErrorText: errString, submitLoading: false})
      })
    }
  }

  render() {
    return (
      <Form
        header={<h1>{this.props.initialState ? <>Edit Partner</> : <>Add New Partner</>}</h1>}
        errorText={this.state.formErrorText}
        actions={
          // located at the bottom of the form
          <div>
            <Button variant="link" href="#/Dashboard">Cancel</Button>
            {this.props.initialState ?
              <Button onClick={e => this.saveCallback()}
                      loading={this.state.submitLoading}
                      variant="primary">
                Save
              </Button>
            :
              <Button onClick={e => this.submitCallback()}
                      loading={this.state.submitLoading}
                      variant="primary">
                Submit
              </Button>
            }
          </div>
        }
      >
        <PartnerDetailsPanel
          initialState={this.props.initialState}
            hasSubmitted={this.state.hasSubmitted}
            onChange={e => this.setState(prevState => {
              return {
                formState: e.newState,
                formValid: e.valid
              }
            })}
        />
      </Form>
    )
  }
}

export default withRouter(PartnerForm);