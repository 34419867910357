import React from "react";

import {Form} from '@amzn/awsui-components-react';
import {withRouter} from "react-router-dom";
import Embed from './Embed';



class Reporting extends React.Component {

  render() {
    return (
      <Form>
        <Embed/>
      </Form>
    )
  }
}

export default withRouter(Reporting);