import React from "react";
import {Input} from "@amzn/awsui-components-react";
import Icon from "@amzn/awsui-components-react/polaris/icon";

export default class ValidatedInput extends React.Component {
  constructor(props) {
    super(props)

    let value = ""
    if (props.hasOwnProperty("value")) {
      value = props.value
    }
    this.state = {fieldText: value, invalid: false, errorText: "", lastUpdate: 0}

    this.conditionalValidate = this.conditionalValidate.bind(this)
    this.is_valid = this.is_valid.bind(this)
  }

  componentDidMount() {
    this.conditionalValidate(this.props.value ? this.props.value : "")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const valueChanged = prevProps.value !== this.props.value;
    if (valueChanged && this.props.hasOwnProperty("value")) {
      this.setState({fieldText: this.props.value})
      this.conditionalValidate(this.props.value)
    }
  }

  async is_valid(fieldText) {
    return [true, ""]
  }

  conditionalValidate(fieldText, e = null) {
    this.setState({fieldText: fieldText})
    let reqTime = Date.now();
    this.is_valid(fieldText).then(result => {
      if (this.state.lastUpdate < reqTime) {
        const [valid, errormsg] = result

        const propogateEvent = () => {
          if (e !== null) {
            if (this.props.hasOwnProperty("onInput")) {
              this.props.onInput({...e, 'detail': {...e.detail, 'valid': valid}})
            }
          }
        }

        this.setState({
          invalid: !valid,
          errorText: errormsg,
          lastUpdate: reqTime
        }, propogateEvent)
      }
    })
  }

  render() {
    return (
      <div className={"display-inline"}>
        <div className={"display-inline"}>
          <Input {...this.props}
                 value={this.state.fieldText}
                 onInput={e => {
                   this.conditionalValidate(e.detail.value, e)
                 }}
                 invalid={this.state.invalid && this.props.hasSubmitted === true}
          />
        </div>
        {this.state.invalid && this.props.hasSubmitted === true ?
          <span className="error-text awsui-util-pt-xs display-block awsui-form-field-description">
            <Icon name="status-warning"/> {this.state.errorText}</span> : null}
      </div>
    )
  }
}