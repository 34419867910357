import {AppLayout, BreadcrumbGroup, Spinner} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import {ErrorAlert} from "../FormComponents/RequestHelpers";
import {API, graphqlOperation} from "aws-amplify";
import {getPartner} from "../../graphql/psaLeaderAccess";
import PartnerForm from "./PartnerForm";

const Breadcrumbs = ({partnerName}) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: partnerName,
        href: '#/Dashboard'
      },
      {
        text: 'Edit'
      }
    ]}
  />
);

export const PartnerLoading = () => {
  return (
    <div>
      <h1><Spinner size={'big'}/> Loading Partner</h1>
    </div>
  )
}

export default class EditPartnerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolsOpen: false,
      initialFormState: null,
      errorText: ''
    }

    this.fetchPartner = this.fetchPartner.bind(this)
  }

  componentDidMount() {
    this.fetchPartner()
  }

  async fetchPartner() {
    this.setState({errorText: ''})
    try {
      const itemsRes = await API.graphql(graphqlOperation(getPartner, {'id': this.props.match.params.id}))

      this.setState({
        initialFormState: {
          id: itemsRes.data.getPartner.id,
          name: itemsRes.data.getPartner.name,
          partnerLeaderAlias: itemsRes.data.getPartner.partnerLeaderAlias,
          partnerSecondaryAlias: itemsRes.data.getPartner.partnerSecondaryAlias,
          allocatedBudget: itemsRes.data.getPartner.allocatedBudget,
        }
      })
    } catch (e) {
      console.log("Error fetching partner: ", e)
      this.setState({errorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  render() {
    const {initialFormState} = this.state
    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState} />}
        notifications={<ErrorAlert
                header={"Error Loading Partner"}
                errorText={this.state.errorText}
                onRetry={this.fetchFundingRequest}/>}
        breadcrumbs={<Breadcrumbs partnerName={initialFormState ? initialFormState.name : "Loading..."}/>}
        content={initialFormState ? <PartnerForm initialState={initialFormState}/> : <PartnerLoading/>}
        contentType="form"
        toolsHide={true}
      />
    )
  }
}