import React from "react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from './CreditCodeListConfig';

import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Table,
  TableContentSelector,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSorting,
  TableWrapLines,
} from '@amzn/awsui-components-react';
import Navigation from "../Navigation/Navigation";
import {API, graphqlOperation} from "aws-amplify";
import {parse} from "query-string"
import Flash from "@amzn/awsui-components-react/polaris/flash/flash";
import {listCreditCodes} from "../../graphql/autogen/queries";
import ViewCreditCodeModal from "./ViewCreditCodeModal";
import {withRouter} from "react-router-dom";
import {updateCreditCode} from "../../graphql/autogen/mutations";


class ImportPendingMessage extends React.Component {
  render() {
    return (
      <Flash type="success"
             header="Code Import Started Sucessfully"
             dismissible={true}
             onDismiss={this.props.onDismiss}>
        The credit code import was started successfully. Depending on the size of the imported file, it may take up to
        15 minutes before all codes are available for use.
      </Flash>
    )
  }
}


class CreditCodeListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      itemsLoading: true,
      creditCodes: [],
      nextToken: null,
      viewCodeDialog: {
        currentCode: null,
        markCodeAssignedErrorText: null
      }
    }

    this.refreshCreditCodes = this.refreshCreditCodes.bind(this)
    this.markCurrentlySelectedCodeAssigned = this.markCurrentlySelectedCodeAssigned.bind(this)
  }

  async refreshCreditCodes(nextToken = null) {
    this.setState({itemsLoading: true, errorText: ''})
    try {
      const itemsRes = await API.graphql(
        graphqlOperation(listCreditCodes, {
          nextToken: nextToken
        })
      );

      if (nextToken) {
        this.setState(prevState => {
          return {
            creditCodes: [...prevState.creditCodes, ...itemsRes.data.listCreditCodes.items],
            nextToken: itemsRes.data.listCreditCodes.nextToken
          }
        })
      } else {
        this.setState({
          creditCodes: itemsRes.data.listCreditCodes.items,
          nextToken: itemsRes.data.listCreditCodes.nextToken
        })
      }
    } catch (e) {
      console.log("Error loading credit codes: ", e)
      this.setState({errorText: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({itemsLoading: false})
    }
  }

  async markCurrentlySelectedCodeAssigned() {
    this.setState(prevState => {
      return {
        viewCodeDialog: {...prevState.viewCodeDialog, markCodeAssignedErrorText: null}
      }
    })
    try {
      const codeRes = await API.graphql(graphqlOperation(
        updateCreditCode,
        {'input': {'id': this.state.viewCodeDialog.currentCode.id, 'status': 'Assigned'}}
      ))
      this.setState(prevState => {
        return {
          viewCodeDialog: {...prevState.viewCodeDialog, currentCode: codeRes.data.updateCreditCode}
        }
      })
    } catch (e) {
      console.log("Error marking credit code assigned: ", e)
      this.setState(prevState => {
        return {
          viewCodeDialog: {
            ...prevState.viewCodeDialog,
            markCodeAssignedErrorText: e.errors ? e.errors[0].message : e.toString()
          }
        }
      })
    }
  }

  componentDidMount() {
    this.refreshCreditCodes()
  }

  render() {
    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState}/>}
        notifications={
          <>
            {parse(this.props.location.search).message === "importPending" ?
              <ImportPendingMessage onDismiss={() => this.props.history.push("/CreditCode/list")}/>
              :
              null
            }
            <ErrorMessage errorText={this.state.errorText} onRetry={() => this.refreshCreditCodes()}/>
          </>
        }
        breadcrumbs={<Breadcrumbs/>}
        content={
          <>
            <DetailsTable
              authState={this.props.authState}
              itemsLoading={this.state.itemsLoading}
              creditCodes={this.state.creditCodes}
              hasMore={this.state.nextToken !== null}
              onRefresh={(useNextToken) => useNextToken ?
                this.refreshCreditCodes(this.state.nextToken)
                :
                this.refreshCreditCodes()
              }
              onRevealCode={(code) => this.setState(prevState => {
                return {
                  viewCodeDialog: {...prevState.viewCodeDialog, currentCode: code}
                }
              })}
            />
            <ViewCreditCodeModal code={this.state.viewCodeDialog.currentCode}
                                 markCodeAssignedError={this.state.viewCodeDialog.markCodeAssignedErrorText}
                                 onDismiss={() => this.setState(prevState => {
                                   return {
                                     viewCodeDialog: {currentCode: null, markCodeAssignedErrorText: null}
                                   }
                                 })}
                                 onMarkCurrentCodeAssigned={this.markCurrentlySelectedCodeAssigned}
            />
          </>
        }
        contentType="table"
        toolsHide={true}
      />
    );
  }
}

class ErrorMessage extends React.Component {
  render() {
    return (
      this.props.errorText ?
        <Flash header="Failed to fetch credit codes"
               type="error"
               buttonText="Retry"
               onButtonClick={this.props.onRetry}>
          {this.props.errorText}
        </Flash>
        : null
    )
  }
}

class DetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 30,
      pagesCount: 0
    };
  }

// Keeps track of how many funding requests are selected
  headerCounter(items, hasMore) {
    return hasMore
      ? `(${items.length}+)`
      : `(${items.length})`;
  }

// Updates the page size in preferences
  onPaginationChange({detail: {pageSize, pagesCount}}) {
    this.setState({
      pageSize,
      pagesCount
    });
  }

  render() {
    return (
      <Table
        columnDefinitions={COLUMN_DEFINITIONS((code) => {
          this.props.onRevealCode(code)
        })}
        items={this.props.creditCodes}
        loading={this.props.itemsLoading}
        loadingText={"Loading credit codes..."}
        header={
          <Header
            counter={this.headerCounter(this.props.creditCodes, this.props.hasMore)}
            itemsLoading={this.props.itemsLoading}
            onRefresh={() => this.props.onRefresh()}
          />
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-xs awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">No results match your query</p>
          </div>
        }
        features={['pagination', 'sorting']}
        empty={<div className="awsui-util-p-m">
          <div className="awsui-util-pb-s">No credit codes</div>
          <Button href="#/CreditCode/Import">Import Codes</Button>
        </div>}
      >
        <TablePagination
          openEnd={this.props.hasMore}
          onPaginationChange={this.onPaginationChange.bind(this)}
          onNextPageClick={(event => {
              if (!event.detail.requestedPageAvailable || event.detail.requestedPageIndex >= this.state.pagesCount - 1) {
                this.props.onRefresh(true)
              }
            }
          )}
          pageSize={this.state.pageSize}
        />
        <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS}/>
          <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" value={false}/>
          <TableContentSelector title="Select visible columns" options={CONTENT_SELECTOR_OPTIONS}/>
        </TablePreferences>
      </Table>
    );
  }
}

// Table header content, shows how many distributions are selected and contains the action stripe
const Header = ({counter, itemsLoading, onRefresh}) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>
          Credit Codes
          {counter ? <span className="awsui-util-header-counter"> {counter}</span> : ''}
        </h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" loading={itemsLoading} onClick={onRefresh}/>
        <Button href="#/CreditCode/import" variant="primary">Import Codes</Button>
      </div>
    </div>
  );
};

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Credit Codes',
        href: '#/CreditCode/list'
      }
    ]}
  />
);

export default withRouter(CreditCodeListPage)
