import React from "react";
import {Alert, AttributeEditor, ColumnLayout, Spinner,ExpandableSection} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {v4 as uuidv4} from 'uuid';
import {RequiredInput} from "../FormComponents/RequiredInputs";
import {API, graphqlOperation} from "aws-amplify";
import {listPermittedIndustrys} from "../../graphql/autogen/queries";

class IndustryConfigPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingIndustrys: true,
      fetchingIndustrysError: "",
      databaseIndustrys: new Set(),
      displayedIndustrys: [],
      addIndustrys: {},
      editIndustrys: {},
      invalidIDs: new Set()
    }

    this.definitions = [
      {
        label: () => (<span>Database ID</span>),
        control: (item, index) => {
          return (
            <Input
              value={item.id}
              disabled={true}
            />
          );
        }
      },
      {
        label: () => <span>Primary Industry Name</span>,
        control: (item, index) => {
          return (
            <RequiredInput
              hasSubmitted={this.props.hasSubmitted}
              onInput={e => this.onIndustryNameChange(e, index)}
              value={item.name}
              placeholder="Enter a name..."
            />
          );
        }
      }
    ];

    this.fetchCurrentIndustrys = this.fetchCurrentIndustrys.bind(this)
    this.pushChanges = this.pushChanges.bind(this)
    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this)
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this)
    this.onIndustryNameChange = this.onIndustryNameChange.bind(this)
  }

  async fetchCurrentIndustrys(){
    this.setState({loadingIndustrys: true, fetchingIndustrysError: ""})
    try {
      const IndustrysRes = await API.graphql(graphqlOperation(listPermittedIndustrys))
      this.setState({
        displayedIndustrys: [...IndustrysRes.data.listPermittedIndustrys.items],
        databaseIndustrys: new Set(IndustrysRes.data.listPermittedIndustrys.items.map((industry) => industry.id))
      })
    } catch (e) {
      console.log("Error fetching Industrys: ", e)
      this.setState({fetchingIndustrysError: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({loadingIndustrys: false})
    }
  }

  componentDidMount() {
    this.fetchCurrentIndustrys()
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {addIndustrys: this.state.addIndustrys, editIndustrys: this.state.editIndustrys},
        valid: this.state.invalidIDs.size === 0
      })
    }
  }

  onAddButtonClickHandler() {
    this.setState((prevState) => {
      const newId = uuidv4()
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.add(newId)
      const addIndustrys = {...prevState.addIndustrys}
      addIndustrys[newId] = ''
      return {
        addIndustrys: addIndustrys,
        displayedIndustrys: [...this.state.displayedIndustrys, {'id': newId, 'name': ''}],
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onItemButtonClickHandler({detail: {itemIndex}}) {
    const items = this.state.displayedIndustrys.slice();
    const removedIndustry = items.splice(itemIndex, 1)[0];

    this.setState((prevState) => {
      const addIndustrys = {...prevState.addIndustrys}
      delete addIndustrys[removedIndustry.id]
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.delete(removedIndustry.id)
      return {
        displayedIndustrys: items,
        addIndustrys: addIndustrys,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onIndustryNameChange({detail: {value}}, index) {
    this.setState((prevState) => {
      const items = [...prevState.displayedIndustrys]
      const editedIndustrys = {...prevState.editIndustrys}
      const addedIndustrys = {...prevState.addIndustrys}
      items[index].name = value
      if (prevState.addIndustrys[items[index].id] !== undefined){
        addedIndustrys[items[index].id] = value
      } else {
        editedIndustrys[items[index].id] = value
      }
      let invalidIDs = new Set(prevState.invalidIDs)
      if (value === "") {
        invalidIDs.add(items[index].id)
      } else {
        invalidIDs.delete(items[index].id)
      }
      return {
        displayedIndustrys: items,
        addIndustrys: addedIndustrys,
        editIndustrys: editedIndustrys,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges())
  }


  render() {
    return (
      <ExpandableSection variant="container" header="Manage Industries">
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            {this.state.fetchingIndustrysError ?
              <Alert type="error"
                     header="Error fetching Industries"
                     onButtonClick={this.fetchCurrentIndustrys}
                     buttonText="Retry">
                {this.state.fetchingIndustrysError}
              </Alert>
              :
              null
            }
            {this.state.loadingIndustrys ?
              <div>
                <Spinner/> Loading Industries...
              </div>
              :
              <AttributeEditor
                isItemRemovable={(item) => !this.state.databaseIndustrys.has(item.id)}
                removeButtonText="Remove"
                addButtonText="Add Primary Industry"
                empty="No Industrys Found"
                definition={this.definitions}
                onAddButtonClick={this.onAddButtonClickHandler}
                onRemoveButtonClick={this.onItemButtonClickHandler}
                items={this.state.displayedIndustrys}
              />
            }
          </div>
        </ColumnLayout>
    </ExpandableSection>
    )
  }
}

export default IndustryConfigPanel