import React from "react";
import {Alert, AttributeEditor, ColumnLayout, Spinner,ExpandableSection} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {v4 as uuidv4} from 'uuid';
import {RequiredInput} from "../FormComponents/RequiredInputs";
import {API, graphqlOperation} from "aws-amplify";
import {listPermittedTechs} from "../../graphql/autogen/queries";

class TechConfigPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTechs: true,
      fetchingTechsError: "",
      databaseTechs: new Set(),
      displayedTechs: [],
      addTechs: {},
      editTechs: {},
      invalidIDs: new Set()
    }

    this.definitions = [
      {
        label: () => (<span>Database ID</span>),
        control: (item, index) => {
          return (
            <Input
              value={item.id}
              disabled={true}
            />
          );
        }
      },
      {
        label: () => <span>Tech Name</span>,
        control: (item, index) => {
          return (
            <RequiredInput
              hasSubmitted={this.props.hasSubmitted}
              onInput={e => this.onTechNameChange(e, index)}
              value={item.name}
              placeholder="Enter a name..."
            />
          );
        }
      }
    ];

    this.fetchCurrentTechs = this.fetchCurrentTechs.bind(this)
    this.pushChanges = this.pushChanges.bind(this)
    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this)
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this)
    this.onTechNameChange = this.onTechNameChange.bind(this)
  }

  async fetchCurrentTechs(){
    this.setState({loadingTechs: true, fetchingTechsError: ""})
    try {
      const TechsRes = await API.graphql(graphqlOperation(listPermittedTechs))
      this.setState({
        displayedTechs: [...TechsRes.data.listPermittedTechs.items],
        databaseTechs: new Set(TechsRes.data.listPermittedTechs.items.map((tech) => tech.id))
      })
    } catch (e) {
      console.log("Error fetching Techs: ", e)
      this.setState({fetchingTechsError: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({loadingTechs: false})
    }
  }

  componentDidMount() {
    this.fetchCurrentTechs()
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {addTechs: this.state.addTechs, editTechs: this.state.editTechs},
        valid: this.state.invalidIDs.size === 0
      })
    }
  }

  onAddButtonClickHandler() {
    this.setState((prevState) => {
      const newId = uuidv4()
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.add(newId)
      const addTechs = {...prevState.addTechs}
      addTechs[newId] = ''
      return {
        addTechs: addTechs,
        displayedTechs: [...this.state.displayedTechs, {'id': newId, 'name': ''}],
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onItemButtonClickHandler({detail: {itemIndex}}) {
    const items = this.state.displayedTechs.slice();
    const removedTech = items.splice(itemIndex, 1)[0];

    this.setState((prevState) => {
      const addTechs = {...prevState.addTechs}
      delete addTechs[removedTech.id]
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.delete(removedTech.id)
      return {
        displayedTechs: items,
        addTechs: addTechs,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onTechNameChange({detail: {value}}, index) {
    this.setState((prevState) => {
      const items = [...prevState.displayedTechs]
      const editedTechs = {...prevState.editTechs}
      const addedTechs = {...prevState.addTechs}
      items[index].name = value
      if (prevState.addTechs[items[index].id] !== undefined){
        addedTechs[items[index].id] = value
      } else {
        editedTechs[items[index].id] = value
      }
      let invalidIDs = new Set(prevState.invalidIDs)
      if (value === "") {
        invalidIDs.add(items[index].id)
      } else {
        invalidIDs.delete(items[index].id)
      }
      return {
        displayedTechs: items,
        addTechs: addedTechs,
        editTechs: editedTechs,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges())
  }


  render() {
    return (
      <ExpandableSection variant="container" header="Manage Technologies">
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            {this.state.fetchingTechsError ?
              <Alert type="error"
                     header="Error fetching Techs"
                     onButtonClick={this.fetchCurrentTechs}
                     buttonText="Retry">
                {this.state.fetchingTechsError}
              </Alert>
              :
              null
            }
            {this.state.loadingTechs ?
              <div>
                <Spinner/> Loading Techs...
              </div>
              :
              <AttributeEditor
                isItemRemovable={(item) => !this.state.databaseTechs.has(item.id)}
                removeButtonText="Remove"
                addButtonText="Add Tech"
                empty="No Techs Found"
                definition={this.definitions}
                onAddButtonClick={this.onAddButtonClickHandler}
                onRemoveButtonClick={this.onItemButtonClickHandler}
                items={this.state.displayedTechs}
              />
            }
          </div>
        </ColumnLayout>
    </ExpandableSection>
    )
  }
}

export default TechConfigPanel