import React from "react";
import {ColumnLayout, FormField, FormSection} from "@amzn/awsui-components-react";
import {RequiredInput} from "../FormComponents/RequiredInputs";
import {AliasInput} from "../FormComponents/AliasInputs";
import ValidatedInput from "../FormComponents/ValidatedInput";

class AllocatedBudgetInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      return [false, "Required field"]
    }

    const numeric = Number(fieldText)
    if (isNaN(numeric)) {
      return [false, "Not a number"]
    }

    if (numeric < 0) {
      return [false, "Must be at least $0"]
    }

    if (numeric % 25 !== 0) {
      return [false, "Must be a multiple of $25"]
    }

    return [true, ""]
  }
}


class PartnerDetailsPanel extends React.Component {
  constructor(props) {
    super(props);
    const {initialState} = props

    this.state = {
      formState: {
        name: '',
        partnerLeaderAlias: '',
        partnerSecondaryAlias: '',
        allocatedBudget: null
      },
      fieldValidity: {
        partnerLeaderAliasValid: !!initialState,
        partnerSecondaryAliasValid: !!initialState,
        allocatedBudgetValid: !!initialState,
      }
    }

    if (initialState) {
      for (const prop in this.state.formState) {
        if (this.state.formState.hasOwnProperty(prop) && initialState.hasOwnProperty(prop)) {
          this.state.formState[prop] = initialState[prop] || ''
        }
      }
    }

    this.pushChanges = this.pushChanges.bind(this)
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: this.state.formState,
        valid: (
          this.state.name !== ''
          && this.state.fieldValidity.partnerLeaderAliasValid
          && this.state.fieldValidity.partnerSecondaryAliasValid
          && this.state.fieldValidity.allocatedBudgetValid
        )
      })
    }
  }

  render() {
    const {initialState} = this.props

    return (
      <FormSection header={<h2>Partner Details</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <FormField
              label={
                <span>
                  Name
                </span>
              }
            >
              <RequiredInput value={this.state.formState.name}
                             hasSubmitted={this.props.hasSubmitted}
                             onChange={e => {
                               this.setState((prevState) => {
                                   return {formState: {...prevState.formState, name: e.detail.value}}
                                 }, () => this.pushChanges()
                               )
                             }}/>
            </FormField>

            <FormField
              label={"Partner Leader"}
              description={"The single-threaded PSA leader for this partner"}
            >
              <AliasInput value={initialState ? initialState.partnerLeaderAlias : ""}
                          hasSubmitted={this.props.hasSubmitted}
                          required={true}
                          onInput={e => {
                            this.setState((prevState) => {
                                return {
                                  formState: {...prevState.formState, partnerLeaderAlias: e.detail.value},
                                  fieldValidity: {...prevState.fieldValidity, partnerLeaderAliasValid: e.detail.valid}
                                }
                              }, () => this.pushChanges()
                            )
                          }}/>
            </FormField>

            <FormField
              label={"Partner Secondary"}
              description={"A required secondary contact, to be copied on all emails related to this partner. " +
              "Has the same permissions as the partner leader"}
            >
              <AliasInput value={initialState ? initialState.partnerSecondaryAlias : ""}
                          hasSubmitted={this.props.hasSubmitted}
                          required={true}
                          onInput={e => {
                            this.setState((prevState) => {
                                return {
                                  formState: {...prevState.formState, partnerSecondaryAlias: e.detail.value},
                                  fieldValidity: {...prevState.fieldValidity, partnerSecondaryAliasValid: e.detail.valid}
                                }
                              }, () => this.pushChanges()
                            )
                          }}/>
            </FormField>
            <FormField
              label={"Allocated Budget"}
              hintText="Must be a multiple of $25"
              description={"The total value of credit codes this partner is permitted to spend"}
              stretch={false}
            >
              <span className="awsui-util-pr-xs">$</span>
              <AllocatedBudgetInput className={"custom-input-small"}
                                    type={"number"}
                                    required={true}
                                    hasSubmitted={this.props.hasSubmitted}
                                    value={initialState ? initialState.allocatedBudget.toString() : ''}
                                    onInput={e => {
                                      this.setState((prevState) => {
                                          return {
                                            formState: {...prevState.formState, allocatedBudget: Number(e.detail.value)},
                                            fieldValidity: {...prevState.fieldValidity, allocatedBudgetValid: e.detail.valid}
                                          }
                                        }, () => this.pushChanges()
                                      )
                                    }}/>
            </FormField>
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default PartnerDetailsPanel