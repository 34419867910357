import React from "react";
import {build, enable, evangelize} from "../../resources/BEEFundingDescriptions";
import {ColumnLayout, FormField, FormSection, Tiles} from "@amzn/awsui-components-react";

const PROJECT_TYPES = [
  {
    label: 'Build',
    value: 'Build',
    description: 'Tinker with services in AWS private-beta, Test recent service launches (from last 6-months), ' +
      'or Build GSI Launchpad/Innovation center demos/showcases'
  },
  {
    label: 'Enable (Level 300-400)',
    value: 'Enable',
    description: 'Immersion Days, Bootcamps, CoE Navigator, ACE/Certification Enablement, or Custom PSA-led workshops'
  },
  {
    label: 'Evangelize (Level 100-200)',
    value: 'Evangelize',
    description: 'Hackathons/Gamedays, DevDays, AWS DeepRacer Rally, or This is My Architecture/Code/Model'
  }
]

export default class ProjectTypePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {projectType: this.props.initialState || 'Build'};
  }

  render() {
    const type_details = {
      'Build': build,
      'Evangelize': evangelize,
      'Enable': enable
    }[this.state.projectType]
    return (
      <FormSection header="Project Type">
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <FormField
              label={
                <div>
                  Select a Project Type
                </div>
              }
              stretch={true}
            >
              <Tiles
                items={PROJECT_TYPES}
                value={this.state.projectType}
                onChange={e => {
                  this.setState({projectType: e.detail.value}, () => {
                    if (this.props.hasOwnProperty('onChange')) {
                      this.props.onChange({'newState': this.state})
                    }
                  })
                }}
              />
            </FormField>
            <div>
              {type_details}
            </div>
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}
