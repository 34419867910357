import '../styles/App.css';
import React, { useState, useEffect } from 'react';
import {Route} from "react-router-dom"
import SubmitFundingRequestPage from "./FundingRequestForm/SubmitFundingRequestPage";
import HomeComponent from "./Home";
import FundingRequestListPage from "./FundingRequestList/FundingRequestListPage";
import ViewRequestPage from "./ViewRequest/ViewRequestPage";
import EditFundingRequestPage from "./FundingRequestForm/EditFundingRequestPage";
import {Amplify, Hub, Auth} from 'aws-amplify';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import awsconfig from "../aws-exports"
import config from '../config.json';
import DashboardPage from "./Dashboard/DashboardPage";
import EditPartnerPage from "./PartnerForm/EditPartnerPage";
import AddPartnerPage from "./PartnerForm/AddPartnerPage";
import CreditCodeListPage from "./CreditCodeList/CreditCodeListPage";
import ImportCreditCodesPage from "./CreditCodes/ImportCreditCodesPage";
import GlobalSettingsPage from "./GlobalSettings/GlobalSettingsPage";
import ReportingPage from "./Reporting/reportingPage";
import Authentication from './Auth/Authentication';

const log_level = config.log_level

Amplify.Logger.LOG_LEVEL = log_level;

Amplify.configure(awsconfig);

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

const oauth = {
  domain: config.auth_domain,
  scope: ["openid"],
  redirectSignIn: window.location.protocol + '//' + window.location.host,
  redirectSignOut: window.location.protocol + '//' + window.location.host,
  responseType: 'token'
}
var hasAccess = "start"
var authState = {
      username: "",
      groups: [],
      posix : "",
      email : ""
    }

Auth.configure({oauth});

const App: React.FC = (props) => {
 
  const [isAuthenticated, setIAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

   const refreshPage = () => {
    window.location.reload(true);
  }


  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
        case 'signIn':
            setIAuthenticated(true);
            break;
        case 'signIn_failure':
          setIAuthenticated(false);
            break;
        default:
            break;
    }
  });

  useEffect(() => {
    
    if (window.location.hash.indexOf("#/access_token=") !== -1)
    {
      setIsLoading(true);
    }

    Auth.currentAuthenticatedUser().then(data => {
        const payload = {data}
        authState = {
        username: payload['data']['signInUserSession']['idToken']['payload']['identities'][0].userId,
        groups: payload['data']['signInUserSession']['idToken']['payload']['cognito:groups'],
        posix: payload['data']['signInUserSession']['idToken']['payload']['custom:posix'],
        email: payload['data']['signInUserSession']['idToken']['payload'].email
      }

        hasAccess = authState.posix.includes("aws-partner-gsi-bee")
        setIAuthenticated(true)

      }).catch(() => {
        hasAccess = false
        setIAuthenticated(false);
      });

    }, []);


  const handleSignIn = () => {
    Auth.federatedSignIn({customProvider:"Federate"});
  }

 
  return (

    <div>
      { isAuthenticated  && (hasAccess === true) && 
      <div id="b">
        <div id="c" className="awsui">
          <Route exact path="/" render={(props) => <HomeComponent authState={authState} {...props} {...props}/>}/>
          <Route path="/Dashboard" render={(props) => <DashboardPage authState={authState} {...props} {...props}/>}/>
          <Route path="/FundingRequest/list"
                 render={(props) => <FundingRequestListPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/submit"
                 render={(props) => <SubmitFundingRequestPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/view/:id"
                 render={(props) => <ViewRequestPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/edit/:id"
                 render={(props) => <EditFundingRequestPage authState={authState} {...props}/>}/>
          <Route path="/Partner/edit/:id"
                 render={(props) => <EditPartnerPage authState={authState} {...props}/>}/>
          <Route path="/Partner/add"
                 render={(props) => <AddPartnerPage authState={authState} {...props}/>}/>
          <Route path="/CreditCode/list"
                 render={(props) => <CreditCodeListPage authState={authState} {...props}/>}/>
          <Route path="/CreditCode/import"
                 render={(props) => <ImportCreditCodesPage authState={authState} {...props}/>}/>
          <Route path="/Reporting"
                 render={(props) => <ReportingPage authState={authState} {...props}/>}/>
          <Route path="/EditGlobalSettings"
                 render={(props) => <GlobalSettingsPage authState={authState} {...props}/>}/>
        </div>
      </div>
      }
      { isAuthenticated  && (hasAccess === "start") &&
      <div>
      {refreshPage()}
      </div>
      }
      { isAuthenticated  && (hasAccess === false) &&
        <div id="b">
          <div id="c" className="awsui">
            <div className={"unauth-container"}>
              <Alert header="Unauthorized" type="error">
                User must be a member of a "aws-partner-gsi-bee" posix group to have access to this tool
              </Alert>
            </div>
          </div>
        </div>
      }
      { ! isAuthenticated  && 
        <Authentication isLoading={isLoading} onSignIn={handleSignIn} />
      }
    </div>
  );
}

export default App;
