import React from "react";
import Navigation from "./Navigation/Navigation";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Button from "@amzn/awsui-components-react/polaris/button";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import '../styles/homepage.css'

export default class HomeComponent extends React.Component {
constructor(props) {
    super(props);
  }

  render() {
  var isReadOnly=this.props.authState.groups.indexOf("ReadOnly") !== -1
  var fundingRequestor = this.props.authState.groups.indexOf("FundingRequestors") !== -1
  var portfolioManager = this.props.authState.groups.indexOf("PortfolioManagers") !== -1
    return (
      <AppLayout
        className="awsui-util-no-gutters"
        navigation={<Navigation authState={this.props.authState} isPartnerLeader={this.props.isPartnerLeader} />}
        content={

  <div className="awsui-grid awsui-util-p-s">
    <div className="custom-home__header custom-awsui-util-pt-xxxl awsui-row">
      <div className="col-xxs-12">
        <div className="awsui-row">
          <div className="custom-home__header-title col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
            <div className="awsui-text-large">
              <div>
                <strong>BEE Funding Tool</strong>
              </div>
              <div>streamlined access to funding for partner projects</div>
            </div>
            <p>
              The GSI Build, Enable, Evangelize (BEE) tool enables automatic credit code management and request processing
              for Build, Evangelize, Enable projects with GSI partners.
            </p>
          </div>

          <div className="col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
          {fundingRequestor || portfolioManager ?
            <div className="awsui-util-container awsui-util-mb-n">
              <h2>Get Started</h2>
              <p>Click below to get started submitting a funding request</p>
              <Button href="#/FundingRequest/submit" variant="primary">Create Funding Request</Button>
            </div>
            :
            null
            }
          </div>
        </div>
      </div>
    </div>

    <div className="awsui-util-pt-xxl awsui-row">
      <div className="col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
        {/*<h1>How it works</h1>*/}
        {/*<div className="awsui-util-container">*/}
        {/*  <div className="custom-home-image__placeholder" />*/}
        {/*</div>*/}

        <h1>Benefits and features</h1>
        <div className="awsui-util-container awsui-util-no-gutters">
          <div>
            <ColumnLayout columns={2} borders="vertical" className="awsui-util-no-gutters">
              <div data-awsui-column-layout-root={true} className="awsui-util-mv-l">
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>Input Validation and Guidance</h3>
                  <p>
                    Get your request approved faster by referencing and conforming to the built-in guidelines.
                  </p>
                </div>
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>Automatic Request Routing</h3>
                  <p>Internal stakeholders are automatically notified when a request needs their attention and can
                    act on the request directly from their email client.
                  </p>
                </div>
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>Reporting and analytics</h3>
                  <p>
                    Leadership gets enhanced visibility into funding usage and can view metrics and outcomes in one
                    easy to access, centralized location.
                  </p>
                </div>
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>Automatic Credit Code Distribution</h3>
                  <p>
                    Once your request is approved, the AWS credit codes are automatically emailed to you.
                  </p>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </div>

        <h1>Use cases</h1>
        <div className="awsui-util-container awsui-util-no-gutters">
          <div>
            <ColumnLayout columns={2} borders="vertical" className="awsui-util-no-gutters">
              <div data-awsui-column-layout-root={true} className="awsui-util-mv-l">
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>Partner Immersion Days</h3>
                  <p>
                    Get funding for labs and other interactive sessions to help evangelize the AWS platform with our
                    partners.
                  </p>
                </div>
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>POC Development</h3>
                  <p>
                    Leverage BEE funding to help our partners develop general POCs that aren't tied to a specific
                    customer opportunity.
                  </p>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </div>

        <div className="awsui-util-container awsui-util-no-gutters">
          <div className="awsui-util-container-header">
            <h2>Related Content</h2>
          </div>
          <div>
            <ColumnLayout columns={2} borders="vertical" className="awsui-util-no-gutters">
              <div data-awsui-column-layout-root={true} className="awsui-util-mv-l">
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>
                    <a href={"https://w.amazon.com/bin/view/AWS_Partner_Network/Funding/"}>APN Funding Portal</a>
                  </h3>
                  <p>Use the general APN funding system when a project is out of scope for the BEE Funding Process.</p>
                </div>
                <div className="awsui-util-ph-l awsui-util-mb-m">
                  <h3>
                    <a href="https://w.amazon.com/bin/view/AWS/Teams/GSI/">GSI Team Wiki</a>
                  </h3>
                  <p>General information about the GSI team, our structure, and mission.</p>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </div>
      </div>

      <div className="custom-home__sidebar col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
        <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <h2>Supported Partners</h2>
          </div>
          <ColumnLayout borders="horizontal" columns={2}>
            <div data-awsui-column-layout-root="true">
             <div><span>Accenture</span></div>
              <div><span>Deloitte</span></div>
              <div><span>Capgemini</span></div>
              <div><span>DXC</span></div>
              <div><span>Cognizant</span></div>
              <div><span>IBM</span></div>
              <div><span>Infosys</span></div>
              <div><span>Wipro</span></div>
              <div><span>TCS</span></div>
              <div><span>HCL</span></div>
              <div><span>TechM</span></div>
              <div><span>PwC</span></div>          
              <div><span>Genpact</span></div>
              <div><span>Atos</span></div>            
            </div>
          </ColumnLayout>
        </div>

        <div className="awsui-util-mt-xxl">
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2>Getting started</h2>
            </div>
            <ColumnLayout borders="horizontal">
              <div data-awsui-column-layout-root="true">
                <div>
                  <a href="https://w.amazon.com/bin/view/Gsibeefunding/">
                    GSI BEE Wiki Page
                  </a>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </div>

        <div className="awsui-util-mt-xxl">
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2>More resources</h2>
            </div>
            <ColumnLayout borders="horizontal">
              <div data-awsui-column-layout-root="true">
                <div>
                  <a href="https://app.slack.com/client/T016M3G1GHZ/C01FMTKLLP2">
                    Slack Support
                  </a>
                </div>
              </div>
              <div data-awsui-column-layout-root="true">
                <div>
                  <a href="mailto:aws-gsi-bee-funding@amazon.com">
                    Email Support
                  </a>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </div>
      </div>
    </div>
  </div>            }
        contentType="default"
        navigationOpen={true}
        toolsHide={true}
      />
    )
  }
}

