import React from "react";
import Navigation from "../Navigation/Navigation";
import {AppLayout, BreadcrumbGroup} from "@amzn/awsui-components-react";
import ImportCreditCodesForm from "./ImportCreditCodesForm";


const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Credit Codes',
        href: '#/CreditCode/list'
      },
      {
        text: 'Import'
      }
    ]}
  />
);

export default class ImportCreditCodesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {toolsOpen: false}
  }

  render() {
    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState} />}
        breadcrumbs={<Breadcrumbs />}
        content={<ImportCreditCodesForm/>}
        contentType="form"
        toolsHide={true}
      />
    )
  }
}