import {AppLayout, BreadcrumbGroup} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import Reporting from "./Reporting";

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'BEE Analytics'
      }
    ]}
  />
);

export default class ReportingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {toolsOpen: false}
  }

  render() {
    return (
      <AppLayout
        navigationOpen={false}
        navigation={<Navigation authState={this.props.authState} isPartnerLeader={this.props.isPartnerLeader} />}
        breadcrumbs={<Breadcrumbs />}
        content={<Reporting/>}
        contentType="default"
        toolsHide={true}
      />
    )
  }
}