import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import {listCreditCodeDenominations} from "../../graphql/autogen/queries";
import {ColumnLayout} from "@amzn/awsui-components-react";

export class TotalFundingOverviewPanel extends React.Component {
  constructor(props) {
      super(props);

    this.state = {
      denominations: [],
      loading: false
    }

    this.fetchDenominationStatus = this.fetchDenominationStatus.bind(this)
  }

  async fetchDenominationStatus(){
    this.setState({loading: true})
    try {
      const denominationsRes = await API.graphql(graphqlOperation(listCreditCodeDenominations));
      const sortedCodes = denominationsRes.data.listCreditCodeDenominations.items.sort((a, b) => {
        if (a.value > b.value){
          return 1
        } else if (b.value > a.value){
          return -1
        } else {
          return 0
        }
      })
      this.setState({denominations: sortedCodes})
    } catch (e) {
      console.log("Error fetching denominations: ", e)
    } finally {
      this.setState({loading: false})
    }
  }

  componentDidMount() {
    this.fetchDenominationStatus()
  }


  render() {
    let total = 0
    let available = 0
    for (const denom of this.state.denominations){
      total += denom.value * denom.totalQuantity
      available += denom.value * denom.availableQuantity
    }

    let consumed = total - available

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Overall Funding Status (Current Year)</h2>
            </div>
          </div>
        </div>
        <ColumnLayout columns={1} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-label">Total Funding</div>
              <span className="awsui-text-large awsui-util-pb-n">
                ${total.toLocaleString()}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Consumed Funding</div>
              <span className="awsui-text-large awsui-util-pb-n">
                ${consumed.toLocaleString()}
              </span>
            </div>
          </div>
        </ColumnLayout>
      </div>
    )
  }
}