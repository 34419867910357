import {AppLayout, BreadcrumbGroup} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import FundingRequestForm from "./FundingRequestForm";

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Funding Requests',
        href: '#/FundingRequest/list'
      },
      {
        text: 'Submit',
        href: '#/FundingRequest/submit'
      }
    ]}
  />
);

export default class SubmitFundingRequestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {toolsOpen: false}
  }

  render() {
    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState} />}
        breadcrumbs={<Breadcrumbs />}
        content={<FundingRequestForm/>}
        contentType="form"
        toolsHide={true}
      />
    )
  }
}