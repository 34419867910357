import React from 'react';
import { API, Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import awsconfig from "../../aws-exports";

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const useStyles = theme => ({
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
});

class Embed extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true
        };
    }
    
    componentDidMount() {
        this.getQuickSightDashboardEmbedURL();
    }
    
    getQuickSightDashboardEmbedURL = async () => {
        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload['custom:USER_PRINCIPAL_NAME']
        const role = data.idToken.payload['cognito:preferred_role']
        const posix = data.idToken.payload['custom:posix']
        const user_pool = data.idToken.payload.iss.replace("https://","")
        const identity_pool = awsconfig.aws_cognito_identity_pool_id;
        console.log(data)
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {  // OPTIONAL
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                role: role,
                user_pool : user_pool,
                identity_pool: identity_pool,
                posix: posix

            }
        }
        const quicksight = await API.get('quicksight', '/getQuickSightDashboardEmbedURL', params);
        console.log(quicksight);
        const containerDiv = document.getElementById("dashboardContainer");
        
        const options = {
            url: quicksight.data.data.EmbedUrl + "&printEnabled=true",
            container: containerDiv,
            parameters: {
                country: "United States"
            },
            scrolling: "no",
            printEnabled: true, //to download report
            height: "AutoFit",
            width: "100%",
            footerPaddingEnabled: true
        };
        console.log("options" + options )
        const dashboard = QuickSightEmbedding.embedDashboard(options);
        this.setState({ loader: false });
    };
    
    render() {
        const { classes } = this.props;
        return (
            <div>
                { this.state.loader && (
                    <div className={classes.loading}> <CircularProgress /> </div>
                )}
                <div id="dashboardContainer"></div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Embed);