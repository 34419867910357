import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import {API, graphqlOperation} from "aws-amplify";
import {listPartners} from "../../graphql/portfolioManagerAccess";

const PARTNER_COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: () => 'Name',
    cell: item => <a href={"#/Partner/edit/" + item.id}>{item.name}</a>,
    minWidth: '200px',
    allowLineWrap: true
  },
  {
    id: 'psaLeader',
    header: () => 'Leader',
    cell: item => item.partnerLeader ? <>{item.partnerLeader.fullName} ({item.partnerLeaderAlias + "@"})</> : item.partnerLeaderAlias + "@",
    minWidth: '120px',
    allowLineWrap: false
  },
  {
    id: 'psaSecondary',
    header: () => 'Secondary',
    cell: item => item.partnerSecondary ? <>{item.partnerSecondary.fullName} ({item.partnerSecondaryAlias + "@"})</> : item.partnerSecondaryAlias + "@",
    minWidth: '120px',
    allowLineWrap: false
  },
  {
    id: 'allocatedBudget',
    header: () => 'Allocated Budget',
    cell: item => '$' + item.allocatedBudget,
    minWidth: '50px',
    allowLineWrap: false
  },
  {
    id: 'remainingBudget',
    header: () => 'Remaining Budget',
    cell: item => '$' + (item.allocatedBudget - item.consumedBudget),
    minWidth: '50px',
    allowLineWrap: false
  },
  {
    id: 'totalFundingRequests',
    header: () => 'Funding Requests (total YTD)',
    cell: item => item.fundingRequests.nextToken ? '999+' : item.fundingRequests.items.length,
    minWidth: '100px',
    allowLineWrap: false
  }
];

export class PartnerOverviewPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      loading: false
    }

    this.fetchPartners = this.fetchPartners.bind(this)
  }


  
  async fetchPartners(){
    this.setState({loading: true})
    try {
      const partnerRequestsRes = await API.graphql(graphqlOperation(listPartners));
      console.log(partnerRequestsRes)
      const sortedPartners = partnerRequestsRes.data.listPartners.items.sort((a, b) => {
        if (a.name > b.name){
          return 1
        } else if (b.name > a.name){
          return -1
        } else {
          return 0
        }
      })
      this.setState({partners: sortedPartners})
    } catch (e) {
      console.log("Error fetching partners: ", e)
    } finally {
      this.setState({loading: false})
    }
  }

  componentDidMount() {
    this.fetchPartners()
  }

  render() {

    const isReadOnly = this.props.isReadOnly
    console.log(isReadOnly)

    return (
      <div className="awsui-util-container awsui-util-no-gutters">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Partner Status</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
            {!isReadOnly ?
              <Button href="#/Partner/add">Add Partner</Button>
            :null }
            </div>
          </div>
        </div>
        <Table
          variant="borderless"
          columnDefinitions={PARTNER_COLUMN_DEFINITIONS}
          items={this.state.partners}
          loading={this.state.loading}
          loadingText={"Loading partners..."}
          empty={<div className="awsui-util-p-m">
            <div className="awsui-util-pb-s">No Partners Found</div>
            {!isReadOnly ?
            <Button href="#/Partner/add">Add a Partner</Button>
          : null }
          </div>}
        >
        </Table>
      </div>
    )
  }
}