import React from "react";
import {Alert, Button, ColumnLayout, Flash, Modal} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import {decryptCreditCode} from "../../graphql/autogen/queries";
import {CreditCodeStatusBadge} from "./CreditCodeListConfig";

export default class ViewCreditCodeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingCreditCode: false,
      fetchCreditCodeErrorText: "",
      creditCodePlaintext: null
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.fetchCreditCode = this.fetchCreditCode.bind(this)
  }

  handleCancel() {
    this.setState({creditCodePlaintext: null, fetchCreditCodeErrorText: "", fetchingCreditCode: false})
    if (this.props.onDismiss) {
      this.props.onDismiss()
    }
  }

  async fetchCreditCode() {
    this.setState({fetchingCreditCode: true, fetchCreditCodeErrorText: ""})
    try {
      const codeRes = await API.graphql(graphqlOperation(
        decryptCreditCode,
        {'creditCodeID': this.props.code.id}
      ))
      this.setState({creditCodePlaintext: codeRes.data.decryptCreditCode})
    } catch (e) {
      console.log("Error decrypting credit code: ", e)
      this.setState({fetchCreditCodeErrorText: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({fetchingCreditCode: false})
    }
  }

  render() {
    const {code} = this.props
    return (
      <Modal
        visible={code !== null}
        header={`View Credit Code`}
        onDismiss={this.handleCancel}
        footer={<span className="awsui-util-f-r">
                <Button variant="link" onClick={this.handleCancel}>
                  Close
                </Button>
          {/*<Button variant="primary"*/}
          {/*        disabled={submitDisabled && action === "Approve"}*/}
          {/*        onClick={this.handleSubmit}*/}
          {/*        loading={this.state.tryingToSubmit || (denominationAvailability === null && action === "Approve")}*/}
          {/*>*/}
          {/*  Confirm*/}
          {/*</Button>*/}
    </span>}
      >
        {code !== null ?
          <ColumnLayout borders="horizontal">
            <div data-awsui-column-layout-root="true">
              {this.state.fetchCreditCodeErrorText ?
                <Flash type="error" header={`Error Fetching Credit Code`}
                       buttonText={"Retry"} onButtonClick={this.fetchCreditCode}>{this.state.fetchCreditCodeErrorText}
                </Flash>
                :
                null
              }
              {this.props.markCodeAssignedError ?
                <Flash type="error" header={`Error Marking Code As Assigned`}
                       buttonText={"Retry"} onButtonClick={this.props.onMarkCurrentCodeAssigned}>
                  {this.props.markCodeAssignedError}
                </Flash>
                :
                null
              }
              <table>
                <tbody>
                <tr>
                  <td className="awsui-util-pr-s awsui-util-pt-s"><b>Internal Code ID</b></td>
                  <td className="awsui-util-pt-s">{code.id}</td>
                </tr>
                <tr>
                  <td className="awsui-util-pr-s awsui-util-pt-s"><b>Denomination</b></td>
                  <td className="awsui-util-pt-s">${code.denomination}</td>
                </tr>
                <tr>
                  <td className="awsui-util-pr-s awsui-util-pt-s"><b>Status</b></td>
                  <td className="awsui-util-pt-s"><CreditCodeStatusBadge status={code.status}/></td>
                </tr>
                <tr>
                  <td className="awsui-util-pr-s awsui-util-pt-s"><b>Code</b></td>
                  <td className="awsui-util-pt-s">{this.state.creditCodePlaintext || "****************"}</td>
                </tr>
                </tbody>
              </table>
              {!this.state.creditCodePlaintext ?
                <div>
                <p>
                  You are about to view a decrypted credit code. This code has real monetary value,
                  and can only be used once. Do NOT distribute or redeem this code unless you know what you're doing.
                </p>
                <p>
                  <Button variant="primary"
                          loading={this.state.fetchingCreditCode}
                          onClick={this.fetchCreditCode}>Reveal Code</Button>
                </p>
                </div>
                :
                null
              }
              {this.state.creditCodePlaintext && this.props.code.status !== "Assigned" ?
                <p>
                  <Alert type="warning"
                         header="Code Not Marked as Utilized"
                         onButtonClick={this.props.onMarkCurrentCodeAssigned}
                         buttonText="Mark Assigned">
                    This code is currently marked as <CreditCodeStatusBadge status={code.status}/>. This means that
                    the BEE Tool may still transmit this code to a funding requestor. If you have just redeemed this
                    code,
                    you should mark it as "Assigned" so that it is not given to another funding request.
                  </Alert>
                </p>
                :
                null
              }
            </div>
          </ColumnLayout>
          :
          null
        }
      </Modal>
    )
  }
}