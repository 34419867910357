import React from 'react';
import {withRouter} from "react-router-dom"
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import {API, graphqlOperation} from "aws-amplify";
import {listMyPartners} from "../../graphql/psaLeaderAccess";
import config from '../../config.json';


class Navigation extends React.Component {
  // If the provided link is empty, do not redirect pages
  constructor(props) {
    super(props);
    this.state = {
      partners: {
        loading: true,
        items: []
      }
    }
    this.fetchPartners = this.fetchPartners.bind(this)
  }
  componentDidMount() {
    this.fetchPartners()
  }

  async fetchPartners() {
    try {
        let partnerRequestsRes = null
        partnerRequestsRes = await API.graphql(graphqlOperation(listMyPartners, {
          alias: this.props.authState.username
        }));
  
      const sortedPartners = partnerRequestsRes.data.listPartners.items.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (b.name > a.name) {
          return -1
        } else {
          return 0
        }
      })

      this.setState({partners: {loading: false, items: sortedPartners}})
    } catch (e) {
      console.log("Error fetching partners: ", e)
      this.setState((prevState) => {
        return {partners: {...prevState.partners, loading: false}}
      })
    }
  }


  render() {
    const isPartnerLeader = this.state.partners.loading ? null : this.state.partners.items.length > 0
    return (
      <SideNavigation
        header={{ text: 'BEE Funding Tool', href: '#/' }}
        items={items(this.props.authState, isPartnerLeader) }
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.constructor.bind(this)}
      />
    );
  }
}

const items = (authState, isPartnerLeader) => {
  const baseItems = [
    { type: 'link', text: 'Dashboard', href: '#/Dashboard' },
    { type: 'link', text: 'View Funding Requests', href: '#/FundingRequest/list' }
    ]
  if (authState.groups.indexOf("PortfolioManagers") !== -1 || authState.groups.indexOf("FundingRequestors") !== -1) {
       baseItems.push({ type: 'link', text: 'Create Funding Request', href: '#/FundingRequest/submit' },)
    }
  if (authState.groups.indexOf("PortfolioManagers") !== -1) {
    baseItems.push({ type: 'link', text: 'Credit Codes', href: '#/CreditCode/list' },)
    baseItems.push({ type: 'link', text: 'Global Configuration', href: '#/EditGlobalSettings' },)
  }
    if (authState.groups.indexOf("ReadOnly") !== -1 || isPartnerLeader || authState.groups.indexOf("PortfolioManagers") !== -1 ) {
    baseItems.push({ type: 'link', text: 'BEE Analytics', href: '#/Reporting' },)
  }

  return baseItems
};

export default withRouter(Navigation);
