import React from "react";
import {ColumnLayout} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";

export class PartnerDashPanel extends React.Component {
  render() {
    const {partner, isPortfolioManager, isReadOnly} = this.props
    var RemainingBudget = partner.allocatedBudget - partner.consumedBudget
    console.log ("XXXXXXXXXXXXXXXXXX",partner)
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Partner - {partner.name}</h2>
            </div>
            {isPortfolioManager || isReadOnly ?
              <div className="awsui-util-action-stripe-group">
              {isPortfolioManager ?
                <Button href={"#/Partner/edit/" + partner.id}>Edit Partner</Button>
              : null }
              </div>
              :
              null
            }
          </div>
        </div>
        <ColumnLayout columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Partner Leader</div>
                  <div><b>{partner.partnerLeader.fullName}</b> ({partner.partnerLeaderAlias}@)</div>
                </div>
                <div>
                  <div className="awsui-util-label">Partner Secondary</div>
                  <div><b>{partner.partnerSecondary.fullName}</b> ({partner.partnerSecondaryAlias}@)</div>
                </div>
              </div>
            </div>
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Allocated Budget</div>
                  <div>${partner.allocatedBudget}</div>
                </div>
                <div>
                  <div className="awsui-util-label">Consumed Budget</div>
                  <div>${partner.consumedBudget}</div>
                </div>
                <div>
                  <div className="awsui-util-label">Remaining Budget</div>
                  <div>${RemainingBudget}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="awsui-util-label">Funding Requests<br/>(Total YTD)</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {partner.fundingRequests.nextToken ? '999+' : partner.fundingRequests.items.length}
              </span>
            </div>
          </div>
        </ColumnLayout>
      </div>
    )
  }
}