import React from "react";
import {
  Alert,
  Button,
  ColumnLayout,
  ExpandableSection,
  Flash,
  FormField,
  Icon,
  Modal,
  Textarea
} from "@amzn/awsui-components-react";
import DenominationSelector from "../FormComponents/DenominationSelector";
import {API, graphqlOperation} from "aws-amplify";
import {getDenominationsForTotal, isDenominationSetAvailable} from "../../graphql/autogen/queries";
import {getPartner} from "../../graphql/psaLeaderAccess";
import {InTextAliasList} from "../FormComponents/TextUtils";

export default class ConfirmActionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      denominationQueryErrorText: "",
      denominationsExpanded: false,
      hasAttemptedSubmit: false,
      requestActionErrorText: "",
      hasModifiedDenominations: false,
      denominationAvailability: null,
      enteredDenominationsValid: true,
      remainingPartnerBudget: null,
      commentText: this.props.preFilledComment || ""
    }

    this.checkDenominationAvailability = this.checkDenominationAvailability.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetToInputDenominations = this.resetToInputDenominations.bind(this)
  }

  async getDenominationsForTotal(total) {
    this.setState({denominationQueryErrorText: ''})
    try {
      const result = await API.graphql(graphqlOperation(getDenominationsForTotal, {'total': total}))
      this.setState({denominationAvailability: result.data.getDenominationsForTotal})
    } catch (e) {
      console.log("Error fetching denominations for total: ", e)
      this.setState({denominationQueryErrorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  async checkDenominationAvailability(denominations) {
    this.setState({denominationQueryErrorText: ''})
    try {
      const result = await API.graphql(graphqlOperation(isDenominationSetAvailable, {'denominations': denominations}))
      this.setState({denominationAvailability: result.data.isDenominationSetAvailable})
    } catch (e) {
      console.log("Error fetching denominations: ", e)
      this.setState({denominationQueryErrorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  async fetchRemainingPartnerBudget() {
    this.setState({denominationQueryErrorText: ''})
    try {
      const result = await API.graphql(graphqlOperation(
        getPartner,
        {'id': this.props.fundingRequest.partnerId}
        ))
      const partner = result.data.getPartner
      this.setState({remainingPartnerBudget: partner.allocatedBudget - partner.consumedBudget})
    } catch (e) {
      console.log("Error fetching partner budget: ", e)
      this.setState({denominationQueryErrorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  resetToInputDenominations() {
    const {requestedDenominations} = this.props.fundingRequest
    if (requestedDenominations) {
      this.checkDenominationAvailability(requestedDenominations)
    } else {
      this.getDenominationsForTotal(this.props.fundingRequest.totalRequested)
    }
  }

  componentDidMount() {
    this.resetToInputDenominations()
    if (this.props.action === "Approve") {
      this.fetchRemainingPartnerBudget()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.preFilledComment !== this.props.preFilledComment) {
      this.setState({commentText: this.props.preFilledComment})
    }

    if (prevProps.fundingRequest !== this.props.fundingRequest) {
      this.resetToInputDenominations()
    }
  }

  handleCancel(){
    this.setState({
      hasAttemptedSubmit: false,
      denominationQueryErrorText: "",
      hasModifiedDenominations: false,
      requestActionErrorText: "",
      tryingToSubmit: false,
      denominationsExpanded: false,
      denominationAvailability: null,
      enteredDenominationsValid: true,
      commentText: this.props.preFilledComment || ""
    })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  handleSubmit(){
    if (!this.state.enteredDenominationsValid) {
      this.setState({hasAttemptedSubmit: true, denominationsExpanded: true})
      return
    }

    const {commentText, denominationAvailability, hasModifiedDenominations} = this.state

    this.setState({requestActionErrorText: "", tryingToSubmit: true})
    this.props.onSubmit(commentText, hasModifiedDenominations ? denominationAvailability.denominations : null)
      .catch((e) => {
        console.log("Error submitting request action: ", e)
        this.setState({requestActionErrorText: e.errors ? e.errors[0].message : e.toString()})
      })
  }

  render() {
    const {fundingRequest, action} = this.props
    const {remainingPartnerBudget, denominationAvailability, denominationQueryErrorText} = this.state

    let totalRequested = 0
    if (denominationAvailability) {
      for (const denomination of denominationAvailability.denominations) {
        totalRequested += denomination.quantity * denomination.value
      }
    }

    const partnerBudgetExceeded = remainingPartnerBudget !== null && (remainingPartnerBudget - totalRequested) < 0
    const denominationsUnavailable = denominationAvailability === null ? false : !denominationAvailability.isAvailable
    const displayedDenominations = denominationAvailability ? denominationAvailability.denominations : []

    const submitDisabled = !remainingPartnerBudget
      || (remainingPartnerBudget - totalRequested) < 0
      || !denominationAvailability
      || !denominationAvailability.isAvailable;

    let newStateBadge = action
    if (action === "Approve") {
      newStateBadge = <span className="awsui-util-status-positive"><Icon name="status-positive"/> Approved</span>
    } else if (action === "Reject") {
      newStateBadge = <span className="awsui-util-status-negative"><Icon name="status-negative"/> Cancelled</span>
    } else if (action === "Withdraw") {
      newStateBadge = <span className="awsui-util-status-inactive"><Icon name="status-stopped"/> Cancelled</span>
    }

    let creditCodeDisbursalEffect = null
    if (action === "Approve"){
      const additionalContacts = fundingRequest.additionalContacts || []
      const allCCed = fundingRequest.projectPSAAlias === fundingRequest.requestorAlias ?
        additionalContacts : [fundingRequest.projectPSAAlias, ...additionalContacts]

      creditCodeDisbursalEffect = <li>
        ${totalRequested} of AWS credit codes will be sent by email to <b>{fundingRequest.requestor.fullName}</b> ({fundingRequest.requestor.alias}@)<br/>
        {allCCed.length > 0 ? <>(<InTextAliasList aliasList={allCCed}/> will be CC'ed)</> : null }
      </li>

    }

    return (
      <Modal
        visible={action !== null}
        header={`Confirm ${action}`}
        onDismiss={this.handleCancel}
        footer={<span className="awsui-util-f-r">
      <Button variant="link" onClick={this.handleCancel}>
        Cancel
      </Button>
      <Button variant="primary"
              disabled={submitDisabled && action === "Approve"}
              onClick={this.handleSubmit}
              loading={this.state.tryingToSubmit || (denominationAvailability === null && action === "Approve")}
      >
        Confirm
      </Button>
    </span>}
      >
        <ColumnLayout borders="horizontal">
          <div data-awsui-column-layout-root="true">
            {this.state.requestActionErrorText ?
              <Flash type="error" header={`Error Submitting ${action} Action`}
                     buttonText={"Retry"} onButtonClick={this.handleSubmit}>{this.state.requestActionErrorText}
              </Flash>
              :
              null
            }
          <span>
            <p>
              This action will have the following effects:
            </p>
            <ul>
              <li>Funding request <b>{fundingRequest.projectName}</b> enters {newStateBadge} state</li>
              {creditCodeDisbursalEffect}
            </ul>
            <p>
              This action cannot be undone. Press "Confirm" below to continue
            </p>
          </span>
            <FormField label={<span>Comment <i> - optional</i></span>}
                       description="Include an optional comment to describe this action">
              <Textarea placeholder={"Describe this action..."}
                        value={this.state.commentText}
                        onChange={(e) => this.setState({commentText: e.detail.value})}/>
            </FormField>
            { action === "Approve" ?
              <>
            <ExpandableSection
              expanded={this.state.denominationsExpanded}
              onChange={(e) => {
                this.setState({denominationsExpanded: e.detail.expanded})
              }}
              header={<span>
                        <p className="no-pad"><b>Modify Funding Amount</b></p>
                        <p className="awsui-form-field-hint">Optionally modify the credit codes that will be
                          used to fulfill this request</p>
                      </span>}
              variant="borderless">
              <DenominationSelector hasSubmitted={this.state.hasAttemptedSubmit}
                                    autoGeneratedDenominations={displayedDenominations}
                                    onChange={(e) => {
                                      this.setState({enteredDenominationsValid: e.detail.valid, hasModifiedDenominations: true})
                                      if (e.detail.valid) {
                                        this.checkDenominationAvailability(e.detail.denominations)
                                      }
                                    }}
              />
            </ExpandableSection>
            {
              denominationQueryErrorText ?
                <Flash type="error" header="Error querying credit code status">{denominationQueryErrorText}</Flash>
                :
                partnerBudgetExceeded ?
                  <Alert type="error" header="Insufficient Funds">
                    You cannot approve this request, since the partner <b>{fundingRequest.partner.name}</b> does
                    not have sufficient funds remaining in their allocated budget.
                  </Alert>
                  :
                  denominationsUnavailable ?
                    <Alert type="error" header="Credit Codes Unavailable">
                      You cannot approve this request, since the credit codes required to fulfill it are
                      unavailable. Ask
                      the portfolio manager to import more credit codes.
                    </Alert>
                    :
                    null
            }
            </>
            : null }
          </div>
        </ColumnLayout>
      </Modal>
    )
  }
}