import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import {Select} from "@amzn/awsui-components-react";
import Icon from "@amzn/awsui-components-react/polaris/icon";

export default class GraphQLModelBackedSelect extends React.Component {
  constructor(props) {
    super(props);
    this.props = props
    this.state = {selectedItem: this.props.initialState || null, itemOptions: [], excludedItems: [], loadingState: "loading"}

    this.fetchItems = this.fetchItems.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
  }

  componentDidMount() {
    this.updateOptions()
  }

  updateOptions() {
    this.setState({loadingState: "loading"})
    this.fetchItems().then(items => {
      this.setState({
        itemOptions: items.map(
          item => {
            return {'id': item[this.props.idField], 'label': (this.props.labelPrefix || "") + item[this.props.labelField]}
          }
        ),
        loadingState: "finished"
      })
    }).catch((e) => {
      console.log("Error fetching items in query: " + this.props.listQuery + ": ", e)
      this.setState({
        loadingState: "error"
      })
    })
  }

  async fetchItems() {
    const itemsRes = await API.graphql(graphqlOperation(this.props.listQuery));
    return itemsRes.data[this.props.listQueryResponseField].items
      .sort((a, b) => {
        if (a[this.props.labelField] < b[this.props.labelField]) {
          return -1
        } else if (a[this.props.labelField] > b[this.props.labelField]) {
          return 1
        } else {
          return 0
        }
      });
  }

  render() {
    const invalid = this.props.required === true && this.props.hasSubmitted === true && this.state.selectedItem === null;
    return (
      <div>
        <Select
          filteringType="auto"
          {...this.props}
          options={this.state.itemOptions.filter(item =>
            this.props.excludeIds ? !this.props.excludeIds.includes(item.id) : true
          )}
          statusType={this.state.loadingState}
          recoveryText="Retry"
          invalid={invalid}
          selectedOption={this.state.selectedItem}
          onChange={e => {
            this.setState({selectedItem: e.detail.selectedOption})
            if (this.props.onChange) {
              this.props.onChange(e)
            }
          }}
          onRecoveryClick={e => {
            this.updateOptions()
          }}
        />
        {invalid ?
          <span className="error-text awsui-util-pt-xs display-block awsui-form-field-description">
            <Icon name="status-warning"/> Required field</span> : null}
      </div>
    )
  }
}