import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import {FundingRequestStatusBadge} from "../FormComponents/FundingRequestStatusBadge";
import {API, graphqlOperation} from "aws-amplify";
import {getFundingRequestsForMyPartners} from "../../graphql/psaLeaderAccess";
import {listFundingRequestsSortBySubmitted} from "../../graphql/standardAccess";
import {dataScopeDisclaimer} from "../FormComponents/RequestHelpers";

const NUM_RECENT_REQUESTS_TO_DISPLAY = 5

const COLUMN_DEFINITIONS = [
  {
    id: 'projectName',
    header: () => 'Project Name',
    cell: item => (
      <div>
        <a href={`#/FundingRequest/view/${item.id}`}>{item.projectName}</a>
      </div>
    ),
    minWidth: '240px',
    allowLineWrap: true
  },
  {
    id: 'partner',
    header: () => 'Partner',
    cell: item => item.partner.name,
    minWidth: '180px',
    allowLineWrap: true
  },
    {
    id: 'geo',
    header: () => 'Area',
    cell: item => item.geo.name,
    minWidth: '140px',
    allowLineWrap: true
  },
    {
    id: 'industry',
    header: () => 'Primary Industry',
    cell: item => item.industry.name,
    minWidth: '120px',
    allowLineWrap: true
  },
  {
    id: 'tech',
    header: () => 'Primary Technology',
    cell: item => item.tech.name,
    minWidth: '160px',
    allowLineWrap: true
  },
  {
    id: 'amount',
    header: () => 'Requested Amount',
    cell: item => '$' + item.totalRequested,
    minWidth: '120px',
    allowLineWrap: true
  },
  {
    id: 'status',
    header: () => 'Status',
    cell: item => <FundingRequestStatusBadge status={item.status}/>,
    minWidth: '200px',
    allowLineWrap: true
  },
  {
    id: 'submittedAt',
    header: () => 'Submitted At',
    cell: item => new Date(item.submittedAt).toString().split(' (')[0],
    minWidth: '280px',
    allowLineWrap: true
  }
];

export class RecentRequestsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      fundingRequests: []
    }

    this.fetchRecentFundingRequests = this.fetchRecentFundingRequests.bind(this)
  }

  async fetchRecentFundingRequests() {
    this.setState({loading: true})
    try {
      let allRequests = {}
      if (this.props.isPartnerLeader && !this.props.isPortfolioManager && !this.props.isReadOnly) {
        const partnerRequestsRes = await API.graphql(graphqlOperation(getFundingRequestsForMyPartners, {
          leaderAlias: this.props.username.replace("Federate_",""),
          limit: NUM_RECENT_REQUESTS_TO_DISPLAY,
          sortDirection: 'DESC'
        }));

        for (const partner of partnerRequestsRes.data.listPartners.items) {
          for (const fundingRequest of partner.fundingRequests.items) {
            allRequests[fundingRequest.id] = fundingRequest
          }
        }
      }

      let nextToken = null
      do {
        const itemsRes = await API.graphql(
          graphqlOperation(listFundingRequestsSortBySubmitted, {
            sortDirection: 'DESC',
            limit: NUM_RECENT_REQUESTS_TO_DISPLAY,
            nextToken: nextToken
          })
        );

        for (const fundingRequest of itemsRes.data.listFundingRequestsSortBySubmitted.items){
          allRequests[fundingRequest.id] = fundingRequest
        }

        nextToken = itemsRes.data.listFundingRequestsSortBySubmitted.nextToken
      } while (nextToken !== null)


      allRequests = Object.values(allRequests).sort((a, b) => {
        if (a.submittedAt > b.submittedAt) {
          return -1
        } else if (a.submittedAt < b.submittedAt) {
          return 1
        } else {
          return 0
        }
      })

      this.setState({fundingRequests: allRequests.slice(0, NUM_RECENT_REQUESTS_TO_DISPLAY)})
    } catch (e) {
      console.log("Error fetching recent funding requests: ", e)
    } finally {
      this.setState({loading: false})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isPartnerLeader !== prevProps.isPartnerLeader) {
      this.fetchRecentFundingRequests()
    }
  }

  render() {
    const {isReadOnly} = this.props.isReadOnly
return (
      <div className="awsui-util-container awsui-util-no-gutters">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Recent Funding Requests</h2>
              <div className="awsui-util-container-header-description">
                {dataScopeDisclaimer(this.props.isPortfolioManager, this.props.isPartnerLeader,this.props.isReadOnly)}
              </div>
            </div>
            { !isReadOnly &&
            <div className="awsui-util-action-stripe-group">
              <Button variant="primary" href="#/FundingRequest/submit">Submit New</Button>
            </div>
          }
          </div>
        </div>
        <div>
          <Table
            variant="borderless"
            columnDefinitions={COLUMN_DEFINITIONS}
            items={this.state.fundingRequests}
            loading={this.state.loading}
            loadingText={"Loading funding requests..."}
            empty={<div className="awsui-util-p-m">
              <div className="awsui-util-pb-s">No funding requests found</div>
              { !isReadOnly &&
              <Button href="#/FundingRequest/submit">Create new request</Button>
            }
            </div>}
          >
          </Table>
        </div>
        {this.state.fundingRequests.length > 0 ?
          <div>
            <div className="awsui-util-container-footer awsui-util-p-m awsui-util-t-c">
              <div className="">
                <a href="#/FundingRequest/list">View All Funding Requests</a>
              </div>
            </div>
          </div>
          :
          null
        }
      </div>
    )
  }
}