export const listFundingAppsByStatus = /* GraphQL */ `
    query ListFundingApps ($status: FundingStatus!) {        
        listFundingRequests(filter: {and: [{status: {eq: $status}}, {submittedAt: {ge: "2023"}}]}, limit: 1000) {
            items {
                id
            }
            nextToken
        }
    }
`

export const listFundingAppsInProgress = /* GraphQL */ `
    query ListFundingApps {
    listFundingRequests(
        filter: {and: [{submittedAt: {ge: "2023"}}, {or: [{status: {eq: ApprovedPendingCodes}}, {status: {eq: ProjectInProgress}}]}]},
        limit: 1000
    ) {
        items {
            id
        }
        nextToken
    }
}
`

export const listFundingAppsTotal = /* GraphQL */ `
    query ListFundingApps {
    listFundingRequests(
        limit: 1000, filter: {submittedAt: {ge: "2023"}}
    ) {
        items {
            id
        }
        nextToken
    }
}
`
