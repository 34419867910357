import React from "react";
import {decryptCreditCode} from "../../graphql/autogen/queries";
import {listCreditCodesForFundingRequest} from "../../graphql/standardAccess";
import MiniCreditCodeTable from "./MiniCreditCodeTable";
import {Alert} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import Flash from "@amzn/awsui-components-react/polaris/flash/flash";


export default class CreditCodePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsLoading: true,
      creditCodes: [],         
      nextToken: null,
      creditCodeFetchError: "",
      decryptCodeError: "",
      showCodes: false,
      decryptingCodes: false,
      assignedToRequestId: ""
    }

    this.getCreditCodes = this.getCreditCodes.bind(this)
    this.decryptCode = this.decryptCode.bind(this)
    this.decryptCodeAndUpdateState = this.decryptCodeAndUpdateState.bind(this)
    this.decryptCreditCodes = this.decryptCreditCodes.bind(this)   
  }


  async getCreditCodes(nextToken = null) {
    this.setState({itemsLoading: true})
    this.setState({assignedToRequestId: this.props.fundingRequest.id})
    try {
      const creditCodesRes = await API.graphql(
        graphqlOperation(listCreditCodesForFundingRequest, {
          fundingRequestId: this.props.fundingRequest.id,
          nextToken: nextToken
        })
      )

      const creditCodes = creditCodesRes.data.getFundingRequestAuthByPartner.assignedCreditCodes.items
      if (this.state.showCodes) {
        await Promise.all(creditCodes.map(async (code, index)=> {
          try {
            creditCodes[index].decrypted_code = await this.decryptCode(code.id)
          } catch (e) {
            creditCodes[index].decrypted_code = null
          }
        }))
      }
     
      if (nextToken) {
        this.setState(prevState => {
          return {
            creditCodes: [...prevState.creditCodes, ...creditCodes],       
            nextToken: creditCodesRes.data.getFundingRequestAuthByPartner.assignedCreditCodes.nextToken
          }
        })
      } else {
        this.setState({
          creditCodes: creditCodes,        
          nextToken: creditCodesRes.data.getFundingRequestAuthByPartner.assignedCreditCodes.nextToken
        })
      }
    } catch (e) {
      console.log("Error loading credit codes: ", e)
      this.setState({creditCodeFetchError: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({itemsLoading: false})
    }
  }

  async decryptCode(codeId){
    const codeRes = await API.graphql(graphqlOperation(decryptCreditCode, {creditCodeID: codeId}))
    return codeRes.data.decryptCreditCode
  }

  async decryptCodeAndUpdateState(index, codeId) {
    try {
      const decrypted_code = await this.decryptCode(codeId)
      this.setState(prevState => {
        const creditCodes = prevState.creditCodes.slice()
        creditCodes[index].decrypted_code = decrypted_code       
        return {creditCodes: creditCodes}
      })
    } catch (e) {
      console.log("Error decrypting some codes: ", e)
      this.setState(prevState => {
        const creditCodes = prevState.creditCodes.slice()
        creditCodes[index].decrypted_code = null
        return {creditCodes: creditCodes, decryptCodeError: e.errors ? e.errors[0].message : e.toString()}
      })
    }
  }


  async decryptCreditCodes() {
    this.setState({decryptingCodes: true, showCodes: true, decryptCodeError: ""})
    await this.state.creditCodes.forEach((code, index)=> {
      this.decryptCodeAndUpdateState(index, code.id)
    })
    this.setState({decryptingCodes: false})
  }

  componentDidMount() {
    if (["ProjectInProgress", "OutcomeNeeded", "ClosedCompleted"].indexOf(this.props.fundingRequest.status) !== -1) {
      return this.getCreditCodes()
    }
  }

  render() {
    const {fundingRequest} = this.props
    if (["ProjectInProgress", "OutcomeNeeded", "ClosedCompleted"].indexOf(fundingRequest.status) === -1) {
      return null
    }

    if (this.state.creditCodeFetchError && !this.state.itemsLoading) {
      return (
        <Alert type="error"
               header="Error Fetching Assigned Credit Codes"
               buttonText="Retry"
               onButtonClick={this.getCreditCodes}>
          {this.state.creditCodeFetchError}
        </Alert>
      )
    }

    return (
      <>
        {this.state.decryptCodeError ?
          <Flash type="error"
                 header="Error Decrypting Credit Code"
                 buttonText="Retry"
                 onButtonClick={this.decryptCreditCodes}>
            {this.state.decryptCodeError}

          </Flash>
          :
          null
        }
        <MiniCreditCodeTable
          isReadOnly = {this.props.isReadOnly}
          itemsLoading={this.state.itemsLoading}
          creditCodes={this.state.creditCodes}          
          showCreditCodes={this.state.showCodes}
          decryptingCodes={this.state.decryptingCodes}
          hasMore={this.state.nextToken !== null}
          assignedToRequestId = {this.state.assignedToRequestId}
          onRefresh={(useNextToken) => {
            if (useNextToken) {
              if (this.state.nextToken) {
                this.getCreditCodes(this.state.nextToken)
              }
            } else {
              this.getCreditCodes()
            }
          }}
          onShowCodesClick={this.decryptCreditCodes}          
          codesDecrypting={this.state.decryptingCodes}
        />
      </>
    );
  }
}