import React from "react";
import {ColumnLayout, DatePicker, FormField, FormSection} from "@amzn/awsui-components-react";
import {RequiredInput, RequiredTextArea, RequiredDate} from "../FormComponents/RequiredInputs";
import GraphQLModelBackedSelect from "../FormComponents/GraphQLModelBackedSelect";
import {listPartners} from "../../graphql/standardAccess";
import {listPermittedGeos,listPermittedIndustrys,listPermittedTechs} from "../../graphql/autogen/queries";
import {AliasInput, AliasListInput ,UrlInput} from "../FormComponents/AliasInputs";


class RequestDetailsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      partnerId: null,
      geoId: null,
      techId: null,
      industryId: null,
      projectPSAAlias: '',
      projectPSAAliasIsValid: false,
      additionalContacts: '',
      additionalContactsIsValid: true,
      sfdcLinkValid:true,
      projectDate: '',
      sfdclink: '',
      description: '',
    }

    const {initialState} = props

    if (initialState) {
      for (const prop in this.state) {
        if (this.state.hasOwnProperty(prop) && initialState.hasOwnProperty(prop)) {
          if (prop === "additionalContacts") {
            this.state.additionalContacts = initialState.additionalContacts ? initialState.additionalContacts.join(', ') : ""
          } else if (prop === "projectPSAAlias"){
            this.state.projectPSAAlias = initialState.projectPSAAlias
            this.state.projectPSAAliasIsValid = true
          } else {
            this.state[prop] = initialState[prop] || ''
          }
        }
      }
    }

    this.pushChanges = this.pushChanges.bind(this)
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {
          projectName: this.state.projectName,
          partnerId: this.state.partnerId,
          geoId: this.state.geoId,
          industryId: this.state.industryId,
          techId: this.state.techId,
          projectPSAAlias: this.state.projectPSAAlias,
          additionalContacts: this.state.additionalContacts.replace(/\s*,\s*/g, ",").split(",").filter(c => c !== ""),
          projectDate: this.state.projectDate,
          sfdclink: this.state.sfdclink,
          description: this.state.description
        },
        valid: (
          this.state.projectName !== ''
          && this.state.partnerId !== null
          && this.state.geoId !== null
          && this.state.techId !== null
          && this.state.industryId !== null        
          && this.state.description !== ''        
          && this.state.projectDate !== ''
          && this.state.projectPSAAliasIsValid
          && this.state.additionalContactsIsValid
          && this.state.sfdcLinkValid
        )
      })
    }
  }

  render() {
    const {initialState} = this.props
    const initialPartnerSelectOption = this.props.initialState ? {
      id: initialState.partnerId, label: initialState.partner.name
    } : null
    const initialIndustrySelectOption = this.props.initialState ? {
      id: initialState.industryId, label: initialState.industry.name
    } : null
    const initialGeoSelectOption = this.props.initialState ? {
      id: initialState.geoId, label: initialState.geo.name
    } : null
    const initialTechSelectOption = this.props.initialState ? {
      id: initialState.techId, label: initialState.tech.name
    } : null

    return (
      <FormSection header={<h2>Request Information</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <FormField
              label={
                <span>
                  Project Name
                </span>
              }
              description={"A short description to concisely identify this request"}
            >
              <RequiredInput value={this.state.projectName}
                             hasSubmitted={this.props.hasSubmitted}
                             placeholder={"Partner ML/AI Skills Workshop Day"}
                             onChange={e => {
                               this.setState({projectName: e.detail.value}, () => this.pushChanges())
                             }}/>
            </FormField>

            <FormField
              label={"Partner"}
              description="The partner that will be supported by this project"
            >
              <GraphQLModelBackedSelect initialState={initialPartnerSelectOption}
                                        listQuery={listPartners}
                                        disabled={
                                          !!this.props.initialState &&
                                          this.props.initialState.status !== "PendingApproval"
                                        }
                                        listQueryResponseField="listPartners"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select a partner"
                                        loadingText="Loading Partners"
                                        errorText="Error fetching partners."
                                        onChange={e => {
                                          this.setState({partnerId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>

            <FormField
              label={"Area"}
              description="The primary area that this project will take place in"
            >
              <GraphQLModelBackedSelect initialState={initialGeoSelectOption}
                                        listQuery={listPermittedGeos}
                                        listQueryResponseField="listPermittedGeos"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select an area"
                                        loadingText="Loading Areas"
                                        errorText="Error fetching areas."
                                        onChange={e => {
                                          this.setState({geoId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>

            <FormField
              label={"Primary Technologies"}
              description="The primary technology of this project"
            >
              <GraphQLModelBackedSelect initialState={initialTechSelectOption}
                                        listQuery={listPermittedTechs}
                                        listQueryResponseField="listPermittedTechs"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select a technology"
                                        loadingText="Loading Technologies"
                                        errorText="Error fetching technologies."
                                        onChange={e => {
                                          this.setState({techId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>


            <FormField
              label={"Primary Industry"}
              description="The primary industry of this project"
            >
              <GraphQLModelBackedSelect initialState={initialIndustrySelectOption}
                                        listQuery={listPermittedIndustrys}
                                        listQueryResponseField="listPermittedIndustrys"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select an industry"
                                        loadingText="Loading Industrys"
                                        errorText="Error fetching industrys."
                                        onChange={e => {
                                          this.setState({industryId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>          

            <FormField
              label={"Project PSA"}
              description={"The PSA who will conduct this initiative with the partner"}
              hintText={"Without @ and @amazon.com"}
            >
              <AliasInput value={initialState ? initialState.projectPSAAlias : ""}
                          placeholder={"PSA alias"}
                          hasSubmitted={this.props.hasSubmitted}
                          required={true}
                          onInput={e => {
                            this.setState({
                              projectPSAAlias: e.detail.value,
                              projectPSAAliasIsValid: e.detail.valid
                            }, () => this.pushChanges())
                          }}/>
            </FormField>

            <FormField label={<span>Additional Contacts <i> - optional</i></span>}
                       description={"Additional contacts will be able to view and modify the details of this request and will be copied on all communications"}
                       hintText={"Enter up to 20 additional aliases separated by commas,without @ and @amazon.com"}
            >
              <AliasListInput value={initialState && initialState.additionalContacts ? initialState.additionalContacts.join(", ") : ""}
                              placeholder={"alias1, alias2..."}
                              hasSubmitted={this.props.hasSubmitted}
                              onInput={e => {
                                this.setState({
                                  additionalContacts: e.detail.value,
                                  additionalContactsIsValid: e.detail.valid
                                }, () => this.pushChanges())
                              }}
              />
            </FormField>
            <FormField
              label={
                <span>Project Date</span>
              }
              description={"The approximate date when the project will be complete"}
            >
            <RequiredDate
                value={this.state.projectDate}
                hasSubmitted={this.props.hasSubmitted}
                onChange={e => {
                  this.setState({projectDate: e.detail.value}, () => this.pushChanges())
                }}
              />
            </FormField>

            <FormField label={<span>SFDC Link</span>}
              description={"SFDC Link (opportunity association) for this funding request"}
              hintText={"Enter a link or URL"}
            >
              <UrlInput value={this.state.sfdclink}
                placeholder={"Enter a link or URL"}
                hasSubmitted={this.props.hasSubmitted}
                onInput={e => {
                  this.setState({ sfdclink: e.detail.value ,
                    sfdcLinkValid : e.detail.valid
                  }, () => this.pushChanges())
                }}
              />
            </FormField>

            <FormField
              label={"Detailed Request Description"}
              description={"A detailed description of your request, including the approximate number of participants and expected outcomes"}
            >
              <RequiredTextArea
                value={this.state.description}
                placeholder={"Describe the request..."}
                hasSubmitted={this.props.hasSubmitted}
                onChange={e => {
                  this.setState({description: e.detail.value}, () => this.pushChanges())
                }}
              />
            </FormField>

          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default RequestDetailsPanel