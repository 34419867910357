import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {ColumnLayout} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import {listFundingAppsByStatus, listFundingAppsInProgress,listFundingAppsTotal} from "../../graphql/fundingRequestStatusQueries";
import {
  countFundingRequestsForMyPartnersByStatus,
  countInProgressFundingRequestsForMyPartners,
  countFundingRequestsForMyPartnersTotal
} from "../../graphql/psaLeaderAccess";
import {dataScopeDisclaimer} from "../FormComponents/RequestHelpers";

export class FundingRequestDashPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingCount: null,
      inProgressCount: null,
      outcomeNeededCount: null,
      completeCount: null,
      totalCount: null,
      rejectedCount: null
    }

    this.updateCounts = this.updateCounts.bind(this)
  }

  async updateCounts() {
    try {
      const totalRes = await API.graphql(
        graphqlOperation(listFundingAppsTotal)
      );
      const rejectedRes = await API.graphql(
        graphqlOperation(listFundingAppsByStatus, {status: "ClosedRejected"})
      );
      const pendingRes = await API.graphql(
        graphqlOperation(listFundingAppsByStatus, {status: "PendingApproval"})
      );
      const inProgressRes = await API.graphql(
        graphqlOperation(listFundingAppsInProgress)
      );
      const outcomeNeededRes = await API.graphql(graphqlOperation(
        listFundingAppsByStatus, {status: "OutcomeNeeded"})
      );
      const completeCountRes = await API.graphql(
        graphqlOperation(listFundingAppsByStatus, {status: "ClosedCompleted"})
      );
      const leader_name = this.props.username.replace("Federate_","")

      const partnerCounts = {
        partnerTotalRes: 0,
        partnerRejectedRes: 0,        
        partnerPendingRes: 0,
        partnerInProgressRes: 0,
        partnerOutcomeNeededRes: 0,
        partnerCompleteCountRes: 0
      }
      console.log("this.props.isPartnerLeader", this.props.isPartnerLeader)
      console.log("this.props.isReadOnly", this.props.isReadOnly)
      if (this.props.isPartnerLeader && !this.props.isPortfolioManager && !this.props.isReadOnly) {
        console.log("this.props.isPartnerLeader && !this.props.isPortfolioManager && !this.props.isReadOnly")
        const partnerTotalRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersTotal,
            {leader: leader_name})
        );        
        const partnerRejectedRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: leader_name, status: "ClosedRejected"})
        );

        const partnerPendingRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: leader_name, status: "PendingApproval"})
        );

        const partnerInProgressRes = await API.graphql(
          graphqlOperation(
            countInProgressFundingRequestsForMyPartners,
            {leader: leader_name})
        );
        console.log(partnerInProgressRes)
        const partnerOutcomeNeededRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: leader_name, status: "OutcomeNeeded"})
        );
        console.log(partnerOutcomeNeededRes)
        const partnerCompleteCountRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: leader_name, status: "ClosedCompleted"})
        );
        const partnerResults = {
          partnerTotalRes,
          partnerRejectedRes,
          partnerPendingRes,
          partnerInProgressRes,
          partnerOutcomeNeededRes,
          partnerCompleteCountRes
        }
        console.log(partnerResults)

        for (const resultType in partnerResults) {
          const result = partnerResults[resultType]
          partnerCounts[resultType] = result.data.listPartners.items.map(
            (partner) => partner.fundingRequests.nextToken ? 1000 : partner.fundingRequests.items.length
          ).reduce((accum, curVal) => accum + curVal)
        }
      }
      console.log("pendingRes.data.listFundingRequests.items",pendingRes.data.listFundingRequests.items)
      const totalCount = totalRes.data.listFundingRequests.items.length
      const rejectedCount = rejectedRes.data.listFundingRequests.items.length
      const pendingCount = pendingRes.data.listFundingRequests.items.length
      const inProgressCount = inProgressRes.data.listFundingRequests.items.length
      const outcomeNeededCount = outcomeNeededRes.data.listFundingRequests.items.length
      const completeCount = completeCountRes.data.listFundingRequests.items.length
      let totalTotal =''
      let totalRejected =''
      let totalPending =''
      let totalInProgress =''
      let totalOutcomeNeeded =''
      let totalComplete =''



      
      if (partnerCounts.partnerTotalRes >= 1) {  totalTotal = partnerCounts.partnerTotalRes  }
      else  {totalTotal = totalCount }      
      if (partnerCounts.partnerRejectedRes >= 1) {  totalRejected = partnerCounts.partnerRejectedRes  }
      else  {totalRejected = rejectedCount }      
      if (partnerCounts.partnerPendingRes >= 1) {  totalPending = partnerCounts.partnerPendingRes  }
      else  {totalPending = pendingCount }
      if (partnerCounts.partnerPendingRes >= 1) {  totalInProgress = partnerCounts.partnerInProgressRes  }
      else  {totalInProgress = inProgressCount }       
      if (partnerCounts.partnerPendingRes >= 1) {  totalOutcomeNeeded = partnerCounts.partnerOutcomeNeededRes  }
      else { totalOutcomeNeeded = outcomeNeededCount }
      if (partnerCounts.partnerPendingRes >= 1) {  totalComplete = partnerCounts.partnerCompleteCountRes  }
      else { totalComplete = completeCount} 

      this.setState({
        totalCount: totalRes.data.listFundingRequests.nextToken || totalTotal > 999 ? '999+' : totalTotal,
        rejectedCount: pendingRes.data.listFundingRequests.nextToken || totalRejected > 999 ? '999+' : totalRejected,
        pendingCount: pendingRes.data.listFundingRequests.nextToken || totalPending > 999 ? '999+' : totalPending,
        inProgressCount: inProgressRes.data.listFundingRequests.nextToken || totalInProgress > 999 ? '999+' : totalInProgress,
        outcomeNeededCount: outcomeNeededRes.data.listFundingRequests.nextToken || totalOutcomeNeeded > 999 ? '999+' : totalOutcomeNeeded,
        completeCount: completeCountRes.data.listFundingRequests.nextToken || totalComplete > 999 ? '999+' : totalComplete


      })
    } catch (e) {
      console.log("Error fetching funding request status: ", e)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isPartnerLeader !== prevProps.isPartnerLeader){
      this.updateCounts()
    }
  }

  render() {
    const {pendingCount, inProgressCount, outcomeNeededCount, completeCount, rejectedCount, totalCount} = this.state

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Funding Request Status Overview (YTD)</h2>
              <div className="awsui-util-container-header-description">{
              dataScopeDisclaimer(this.props.isPortfolioManager, this.props.isPartnerLeader, this.props.isReadOnly)
              }</div>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button href="#/FundingRequest/list">View Requests</Button>
            </div>
          </div>

        </div>
        <ColumnLayout columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-label">Total Requests</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {totalCount !== null ? totalCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Pending Approval</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {pendingCount !== null ? pendingCount : '-'}
              </span>
            </div>
          <div>
              <div className="awsui-util-label">Cancelled/Rejected</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {rejectedCount !== null ? rejectedCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Approved - Project in Progress</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {inProgressCount !== null ? inProgressCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Approved - Outcome Required</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {outcomeNeededCount !== null ? outcomeNeededCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Complete</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {completeCount !== null ? completeCount : '-'}
              </span>
            </div>
          </div>
        </ColumnLayout>
      </div>
    )
  }
}