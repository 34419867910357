import React from "react";

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from './FundingRequestListConfig';

import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Table,
  TableContentSelector,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSelection,
  TableSorting,
  TableWrapLines,
} from '@amzn/awsui-components-react';
import Navigation from "../Navigation/Navigation";
import {API, graphqlOperation} from "aws-amplify";
import Flash from "@amzn/awsui-components-react/polaris/flash/flash";
import {listFundingRequestsSortBySubmitted} from "../../graphql/standardAccess";
import {getFundingRequestsForMyPartners, listFundingRequestsForPartner} from "../../graphql/psaLeaderAccess";


export default class FundingRequestListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      itemsLoading: true,
      fundingRequests: [],
      fundingRequestsNextToken: null,
      partnerFundingRequestNextTokens: {}
    }

    this.refreshFundingRequests = this.refreshFundingRequests.bind(this)
    this.checkEmptyAndGetNext = this.checkEmptyAndGetNext.bind(this)
  }

  checkEmptyAndGetNext(){
    if (this.state.fundingRequests.length < 100 && this.state.fundingRequestsNextToken){
      this.refreshFundingRequests(true)
    }
  }

  async refreshFundingRequests(useNextTokens=false) {
    const nextToken = this.state.fundingRequestsNextToken
    this.setState({itemsLoading: true, errorText: ''})
    try {
      let allRequests = {}
      let fundingRequestsNextToken = null
      if (!useNextTokens || nextToken !== null) {
        const itemsRes = await API.graphql(
          graphqlOperation(listFundingRequestsSortBySubmitted, {
            sortDirection: 'DESC',
            nextToken: nextToken
          })
        );

        fundingRequestsNextToken = itemsRes.data.listFundingRequestsSortBySubmitted.nextToken
        const listFundingRequests = itemsRes.data.listFundingRequestsSortBySubmitted.items

        for (const fundingRequest of listFundingRequests) {
          allRequests[fundingRequest.id] = fundingRequest
        }
      }

      let partnerRequests = null
      if (useNextTokens) {
        partnerRequests = await this.fetchNextRequestsForPartners()
        } else {
        partnerRequests = await this.fetchPartnerFundingRequests()
      }

      for (const fundingRequestId in partnerRequests){
        allRequests[fundingRequestId] = partnerRequests[fundingRequestId]
      }

      const fundingRequests = Object.values(allRequests).sort((a, b) => {
        if (a.submittedAt > b.submittedAt) {
          return -1
        } else if (a.submittedAt < b.submittedAt) {
          return 1
        } else {
          return 0
        }
      })

      if (useNextTokens) {
        this.setState(prevState => {return {
          fundingRequests: [...prevState.fundingRequests, ...fundingRequests],
          fundingRequestsNextToken: fundingRequestsNextToken
        }}, this.checkEmptyAndGetNext)
      } else {
        this.setState({
          fundingRequests: fundingRequests,
          fundingRequestsNextToken: fundingRequestsNextToken
        }, this.checkEmptyAndGetNext)
      }
    } catch (e) {
      console.log("Error loading funding requests: ", e)
      this.setState({errorText: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({itemsLoading: false})
    }
  }

  async fetchPartnerFundingRequests(){
    let allRequests = {}
    let nextTokens = {}

    const partnerRequestsRes = await API.graphql(graphqlOperation(getFundingRequestsForMyPartners, {
      leaderAlias: this.props.authState.username,
      sortDirection: 'DESC'
    }));

    for (const partner of partnerRequestsRes.data.listPartners.items) {
      nextTokens[partner.id] = partner.fundingRequests.nextToken
      for (const fundingRequest of partner.fundingRequests.items) {
        allRequests[fundingRequest.id] = fundingRequest
      }
    }

    this.setState({partnerFundingRequestNextTokens: nextTokens})
    return allRequests
  }

  async fetchNextRequestsForPartners(){
    let allRequests = {}
    let nextTokens = {}

    const {partnerFundingRequestNextTokens} = this.state

    for (const partnerId in partnerFundingRequestNextTokens){
      if (partnerFundingRequestNextTokens.hasOwnProperty(partnerId) && partnerFundingRequestNextTokens[partnerId]) {
        const partnerRequestRes = await API.graphql(graphqlOperation(listFundingRequestsForPartner, {
          partnerId: partnerId,
          nextToken: partnerFundingRequestNextTokens[partnerId],
          sortDirection: 'DESC'
        }));

        nextTokens[partnerId] = partnerRequestRes.data.getPartner.fundingRequests.nextToken
        for (const fundingRequest of partnerRequestRes.data.getPartner.fundingRequests.items) {
          allRequests[fundingRequest.id] = fundingRequest
        }
      } else {
        nextTokens[partnerId] = null
      }
    }

    this.setState({partnerFundingRequestNextTokens: nextTokens})
    return allRequests
  }

  componentDidMount() {
    this.refreshFundingRequests()
  }

  render() {
    const {partnerFundingRequestNextTokens} = this.state
    let hasMore = this.state.fundingRequestsNextToken !== null
    for (const partnerId in partnerFundingRequestNextTokens){
      hasMore |= partnerFundingRequestNextTokens[partnerId] !== null
    }

    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState} />}
        notifications={<ErrorMessage errorText={this.state.errorText} onRetry={() => this.refreshFundingRequests()}/>}
        breadcrumbs={<Breadcrumbs/>}
        content={
          <DetailsTable
            authState={this.props.authState}
            itemsLoading={this.state.itemsLoading}
            fundingRequests={this.state.fundingRequests}
            hasMore={!!hasMore}
            onRefresh={(useNextToken) => this.refreshFundingRequests(useNextToken)}
            isPartnerLeader={Object.keys(this.state.partnerFundingRequestNextTokens).length > 0}
          />
        }
        contentType="table"
        toolsHide={true}
      />
    );
  }
}

class ErrorMessage extends React.Component {
  render() {
    return (
      this.props.errorText ?
        <Flash header="Failed to fetch funding requests"
               type="error"
               buttonText="Retry"
               onButtonClick={this.props.onRetry}>
          {this.props.errorText}
        </Flash>
      : null
    )
  }
}

class DetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFundingRequests: [],
      pageSize: 30,
      pagesCount: 0
    };
  }

// Keeps track of how many funding requests are selected
  headerCounter(distributions, hasMore) {
    return hasMore
      ? `(${distributions.length}+)`
      : `(${distributions.length})`;
  }

// Updates the page size in preferences
  onPaginationChange({detail: {pageSize, pagesCount}}) {
    this.setState({
      pageSize,
      pagesCount
    });
  }

  render() {
    const isPortfolioManager = this.props.authState.groups.indexOf("PortfolioManagers") !== -1
    const isReadOnly = this.props.authState.groups.indexOf("ReadOnly") !== -1
    return (
      <Table
        columnDefinitions={COLUMN_DEFINITIONS}
        items={this.props.fundingRequests}
        loading={this.props.itemsLoading}
        loadingText={"Loading funding requests.."}
        header={
          <Header
            selectedRequests={this.state.selectedFundingRequests}
            counter={this.headerCounter(this.props.fundingRequests, this.props.hasMore)}
            itemsLoading={this.props.itemsLoading}
            onRefresh={() => this.props.onRefresh()}
            isApprover={isPortfolioManager || this.props.isPartnerLeader}
            isReadOnly = {isReadOnly}
          />
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-xs awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">No results match your query</p>
          </div>
        }
        features={['pagination', 'selection', 'sorting']}
        empty={<div className="awsui-util-p-m">
          <div className="awsui-util-pb-s">No funding requests</div>
          {!isReadOnly &&
          <Button href="#/FundingRequest/submit">Create new request</Button>
        }
        </div>}
      >
        <TablePagination
          openEnd={this.props.hasMore}
          onPaginationChange={this.onPaginationChange.bind(this)}
          onNextPageClick={(event => {
              if (!event.detail.requestedPageAvailable || event.detail.requestedPageIndex >= this.state.pagesCount - 1){
                this.props.onRefresh(true)
              }
            }
          )}
          pageSize={this.state.pageSize}
        />
        <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
        <TableSelection
          selectionType={'single'}
          selectedItems={this.state.selectedFundingRequests}
          onSelectionChange={evt => this.setState({selectedFundingRequests: evt.detail.selectedItems})}
        />
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS}/>
          <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" value={false}/>
          <TableContentSelector title="Select visible columns" options={
            CONTENT_SELECTOR_OPTIONS(isPortfolioManager || isReadOnly || this.props.isPartnerLeader)
          }/>
        </TablePreferences>
      </Table>
    );
  }
}

// Table header content, shows how many distributions are selected and contains the action stripe
const Header = ({selectedRequests, counter, itemsLoading, onRefresh, isApprover, isReadOnly}) => {
  const isOnlyOneSelected = selectedRequests.length === 1;
  const isEditable = isOnlyOneSelected && !isReadOnly && ["ClosedRejected", "ClosedCompleted"].indexOf(selectedRequests[0].status) === -1
  

  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>
          { isApprover ?
            <>Funding Requests&nbsp;</>
          :
            <>My Funding Requests&nbsp;</>
          }
          {counter ? <span className="awsui-util-header-counter">{counter}</span> : ''}
        </h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" loading={itemsLoading} onClick={onRefresh}/>
        <Button disabled={!isOnlyOneSelected}
                href={selectedRequests.length > 0 ? `#/FundingRequest/view/${selectedRequests[0].id}` : ''}
        >
          View details
        </Button>
        <Button disabled={!isEditable}
                href={selectedRequests.length > 0 ? `#/FundingRequest/edit/${selectedRequests[0].id}` : ''}
        >
          Edit
        </Button>
        {!isReadOnly &&
        <Button href="#/FundingRequest/submit" variant="primary">Create new request</Button>
        }
      </div>
    </div>
  );
};

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Funding Requests',
        href: '#/FundingRequest/list'
      }
    ]}
  />
);