import React from "react";

import {Button, ColumnLayout} from "@amzn/awsui-components-react";
import {FundingRequestStatusBadge} from "../FormComponents/FundingRequestStatusBadge";
import moment from "moment";
import {AliasList, DenominationList} from "../FormComponents/TextUtils";

export const RequestDetails = ({ fundingRequest }) => (
  <ColumnLayout columns={3} variant="text-grid">
    <div data-awsui-column-layout-root="true">
      <div>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">Project Name</div>
            <div>{fundingRequest.projectName}</div>
          </div>
          <div>
            <div className="awsui-util-label">Project Type</div>
            <div>{fundingRequest.projectType}</div>
          </div>
          <div>
            <div className="awsui-util-label">Partner</div>
            <div>{fundingRequest.partner.name}</div>
          </div>
          <div>
            <div className="awsui-util-label">Area</div>
            <div>{fundingRequest.geo.name}</div>
          </div>
          <div>
            <div className="awsui-util-label">Primary Technology</div>
            <div>{fundingRequest.tech.name}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">Status</div>
            <div>
              <FundingRequestStatusBadge status={fundingRequest.status}/>
            </div>
          </div>
          <div>
            <div className="awsui-util-label">Requested By</div>
            <div>
              {fundingRequest.requestor.fullName}
            </div>
          </div>
          <div>
            <div className="awsui-util-label">Project PSA</div>
            <div>
              {fundingRequest.projectPSA.fullName}
            </div>
          </div>
          <div>
            <div className="awsui-util-label">Additional Contacts</div>
            <AliasList aliasList={fundingRequest.additionalContacts}/>
          </div>
          <div>
            <div className="awsui-util-label">Primary Industry</div>
            <div>{fundingRequest.industry.name}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">Project Date</div>
            <div>{fundingRequest.projectDate ? moment(fundingRequest.projectDate).format("dddd, MMMM Do YYYY") : "-"}</div>
          </div>
          <div>
            <div className="awsui-util-label">Request Submitted</div>
            <div>{moment(fundingRequest.submittedAt).calendar()}</div>
          </div>
          <div>
            <div className="awsui-util-label">Total Funding Requested</div>
            <div>${fundingRequest.totalRequested}</div>
          </div>
          <div>
            <div className="awsui-util-label">Denominations Requested</div>
            <DenominationList denominations={fundingRequest.requestedDenominations}/>
          </div>
          <div>
            <div className="awsui-util-label">SFDC Link</div>
            <div>{fundingRequest.sfdclink}</div>
          </div>
        </div>
      </div>
    </div>
  </ColumnLayout>
);

export default class FundingRequestSummaryTable extends React.Component {
  render() {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Overview</h2>
            </div>
            <div className="awsui-util-action-stripe-group awsui-util-pv-n">
              {this.props.isEditor ?
                <Button
                  disabled={["ClosedRejected", "ClosedCompleted"].indexOf(this.props.fundingRequest.status) !== -1}
                  href={`#/FundingRequest/edit/${this.props.fundingRequest.id}`}>
                  Edit
                </Button>
                :
                null
              }
            </div>
          </div>
      </div>
        <RequestDetails fundingRequest={this.props.fundingRequest}/>
      </div>
    );
  }
}