import {Icon} from "@amzn/awsui-components-react";
import React from "react";

const statusToWords = {
    PendingApproval: "Pending Approval",
    ClosedRejected: "Cancelled/Rejected",
    ApprovedPendingCodes: "Pending Codes",
    ProjectInProgress: "Approved - Project In Progress",
    OutcomeNeeded: "Outcome Required",
    ClosedCompleted: "Completed"
}

const FundingRequestStatusBadge = ({status}) => {
  let words = statusToWords[status] || status
      let iconName = "status-info"
      let colorClass = "awsui-util-status-inactive"

      if (status === 'PendingApproval') {
        iconName = "status-pending"
        colorClass = "awsui-util-status-info"
      } else if (status === 'ClosedRejected') {
        iconName = "status-stopped"
        colorClass = "awsui-util-status-inactive"
      } else if (status === 'ApprovedPendingCodes') {
        iconName = "status-pending"
        colorClass = "awsui-util-status-info"
      } else if (status === 'ProjectInProgress'){
        iconName = "status-in-progress"
        colorClass = "awsui-util-status-info"
      } else if (status === 'OutcomeNeeded'){
        iconName = "status-warning"
        colorClass = "awsui-util-status-negative"
      } else if (status === 'ClosedCompleted'){
        iconName = "status-positive"
        colorClass = "awsui-util-status-positive"
      }

      return (
        <div className={colorClass}>
          <Icon name={iconName} />
          <span>{` ${words}`}</span>
        </div>
      )
}

export {statusToWords, FundingRequestStatusBadge}
