import {AppLayout, BreadcrumbGroup} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import GlobalSettingsForm from "./GlobalSettingsForm";

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Edit Global Settings'
      }
    ]}
  />
);

export default class GlobalSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {toolsOpen: false}
  }

  render() {
    return (
      <AppLayout
        navigationOpen={true}
        navigation={<Navigation authState={this.props.authState} />}
        breadcrumbs={<Breadcrumbs />}
        content={<GlobalSettingsForm/>}
        contentType="form"
        toolsHide={true}
      />
    )
  }
}