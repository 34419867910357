import React from 'react';
import {AttributeEditor} from '@amzn/awsui-components-react';
import {listCreditCodeDenominations} from "../../graphql/standardAccess";
import AsyncFuncBackedSelect from "./AsyncFuncBackedSelect";
import {API, graphqlOperation} from "aws-amplify";
import ValidatedInput from "./ValidatedInput";
import {areDenominationsEqual} from "./RequestHelpers";

class DenominationQuantityInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      return [false, "Required field"]
    }

    const numeric = Number(fieldText)
    if (isNaN(numeric)) {
      return [false, "Not a number"]
    }

    if (numeric <= 0) {
      return [false, "Must be at least 1"]
    }

    if (numeric % 1 !== 0) {
      return [false, "Must be an integer value"]
    }

    return [true, ""]
  }
}


export default class DenominationSelector extends React.Component {
  constructor(props) {
    super(props);

    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this);
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this);
    this.onQuantityInputHandler = this.onQuantityInputHandler.bind(this);
    this.pushItemsUpdate = this.pushItemsUpdate.bind(this);
    this.renderValueDropdown = this.renderValueDropdown.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.getItemsForSelect = this.getItemsForSelect.bind(this);

    let autoGeneratedDenominations = props.autoGeneratedDenominations
    if (!autoGeneratedDenominations) {
      autoGeneratedDenominations = []
    }

    this.state = {
      validDenominations: null,
      items: autoGeneratedDenominations,
      invalidQuantityDenominations: new Set()
    };
  }

  async fetchItems() {
    const itemsRes = await API.graphql(graphqlOperation(listCreditCodeDenominations));
    return itemsRes.data.listCreditCodeDenominations.items
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1
        } else if (a.value > b.value) {
          return 1
        } else {
          return 0
        }
      });
  }

  async getItemsForSelect(selectItem) {
    let denominations = this.state.validDenominations
    if (denominations === null) {
      denominations = await this.fetchItems()
      this.setState({validDenominations: denominations})
    }

    const excludedValues = this.state.items.map(item => item.value).filter(v => v !== selectItem.value);
    return denominations.filter(item => !excludedValues.includes(item.value))
      .map(item => {
        return {'id': item.value, 'label': '$' + item.value}
      })
  }

  renderValueDropdown(item, index) {
    return (
      <AsyncFuncBackedSelect
        disabled={!!this.props.disabled}
        loadingText="Loading denominationss"
        errorText="Error fetching denominations."
        fetchItems={async () => await this.getItemsForSelect(item)}
        onChange={e => {
          this.setState(prevState => {
            let items = prevState.items;
            const invalidQuantityDenominations = this.state.invalidQuantityDenominations
            if (invalidQuantityDenominations.has(items[index.value])) {
              invalidQuantityDenominations.delete(items[index.value])
              invalidQuantityDenominations.add(e.detail.selectedId)
            }
            items[index].value = e.detail.selectedId
            return {items: items, invalidQuantityDenominations: invalidQuantityDenominations}
          }, () => {
            this.pushItemsUpdate(this.state.items)
          })
        }}
        required={true}
        hasSubmitted={this.props.hasSubmitted}
        selectedOption={{'label': "$" + item.value, 'id': item.value}}
        placeholder="Select a value"
        filteringType="none"
      />
    );
  }

  componentDidMount() {
    this.fetchItems().then(denominations => this.setState({validDenominations: denominations}))
    if (this.state.items.length > 0) {
      this.pushItemsUpdate(this.state.items)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!areDenominationsEqual(this.props.autoGeneratedDenominations, prevProps.autoGeneratedDenominations)) {
      let autoGeneratedDenominations = this.props.autoGeneratedDenominations
      if (autoGeneratedDenominations === null) {
        autoGeneratedDenominations = []
      }

      this.setState({
        items: autoGeneratedDenominations
      })
    }
  }

  pushItemsUpdate(items) {
    if (this.props.onChange) {
      this.props.onChange({
        'detail': {
          'denominations': items,
          'valid': this.state.invalidQuantityDenominations.size === 0
        }
      })
    }
  }

  onAddButtonClickHandler() {
    let usedValues = this.state.items.map(item => item.value)
    let unusedDenominations = this.state.validDenominations.filter(denom => !usedValues.includes(denom.value));

    this.setState(prevState => {
      const items = [...prevState.items, {'value': unusedDenominations[0].value, 'quantity': 1}]
      return {items: items}
    }, () => {
      this.pushItemsUpdate(this.state.items)
    });
  }

  onItemButtonClickHandler({detail: {itemIndex}}) {
    this.setState(prevState => {
      const items = prevState.items.slice();
      const removedItem = items.splice(itemIndex, 1)[0];
      let invalidQuantityDenominations = new Set(prevState.invalidQuantityDenominations)
      invalidQuantityDenominations.delete(removedItem.value)

      return {items: items, invalidQuantityDenominations: invalidQuantityDenominations}
    }, () => {
      this.pushItemsUpdate(this.state.items)
    });
  }

  onQuantityInputHandler(e, index) {
    this.setState(prevState => {
      let items = prevState.items;
      const numericEntry = Number(e.detail.value)
      if (numericEntry > 0 && numericEntry % 1 === 0) {
        items[index].quantity = numericEntry
        let invalidQuantityDenominations = new Set(prevState.invalidQuantityDenominations)
        invalidQuantityDenominations.delete(items[index].value)
        return {items: items, invalidQuantityDenominations: invalidQuantityDenominations}
      } else {
        items[index].quantity = e.detail.value
        let invalidQuantityDenominations = new Set(prevState.invalidQuantityDenominations)
        invalidQuantityDenominations.add(items[index].value)
        return {items: items, invalidQuantityDenominations: invalidQuantityDenominations}
      }
    }, () => {
      this.pushItemsUpdate(this.state.items)
    })
  }

  render() {
    const definitions = [
      {
        label: () => <span>Denomination Value</span>,
        control: this.renderValueDropdown
      },
      {
        label: () => <span>Quantity</span>,
        control: (item, index) => {
          return (
            <DenominationQuantityInput
              disabled={!!this.props.disabled}
              onInput={e => this.onQuantityInputHandler(e, index)}
              value={Number(item.quantity).toString()}
              type={"number"}
              placeholder="Enter quantity"
              hasSubmitted={this.props.hasSubmitted}
            />
          );
        }
      }
    ];

    let usedValues = this.state.items.map(item => item.value)
    let unusedDenominations = []
    if (this.state.validDenominations) {
      unusedDenominations = this.state.validDenominations.filter(denom => !usedValues.includes(denom.value));
    }

    return (
      <AttributeEditor
        isItemRemovable={() => {
          return !this.props.disabled
        }}
        disableAddButton={unusedDenominations.length === 0 || !!this.props.disabled}
        removeButtonText="Remove"
        addButtonText="Add new denomination"
        empty="No denominations specified"
        definition={definitions}
        onAddButtonClick={this.onAddButtonClickHandler}
        onRemoveButtonClick={this.onItemButtonClickHandler}
        items={this.state.items}
      />
    );
  }
}