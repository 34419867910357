/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrAddPerson = /* GraphQL */ `
  mutation GetOrAddPerson($alias: String!) {
    getOrAddPerson(alias: $alias) {
      alias
      fullName
      email
      createdAt
      updatedAt
    }
  }
`;
export const submitFundingRequest = /* GraphQL */ `
  mutation SubmitFundingRequest($input: CreateFundingRequestInput!) {
    submitFundingRequest(input: $input) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      techId
      tech {
        id
        name
        createdAt
        updatedAt
      }
      industryId
      industry {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      partnerLeaderAlias
      ByLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      BySecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      sfdclink
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          expirationDate
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const updateFundingRequest = /* GraphQL */ `
  mutation UpdateFundingRequest($input: UpdateFundingRequestInput!) {
    updateFundingRequest(input: $input) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      techId
      tech {
        id
        name
        createdAt
        updatedAt
      }
      industryId
      industry {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      partnerLeaderAlias
      ByLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      BySecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      sfdclink
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          expirationDate
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const importCreditCodes = /* GraphQL */ `
  mutation ImportCreditCodes($dataURL: String!) {
    importCreditCodes(dataURL: $dataURL)
  }
`;
export const applyActionAgainstFundingRequest = /* GraphQL */ `
  mutation ApplyActionAgainstFundingRequest(
    $fundingRequestId: ID!
    $action: ActionType!
    $accompanyingComment: String
    $denominationsOverride: [DenominationInput]
  ) {
    applyActionAgainstFundingRequest(
      fundingRequestId: $fundingRequestId
      action: $action
      accompanyingComment: $accompanyingComment
      denominationsOverride: $denominationsOverride
    ) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      techId
      tech {
        id
        name
        createdAt
        updatedAt
      }
      industryId
      industry {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      partnerLeaderAlias
      ByLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      BySecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      sfdclink
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          expirationDate
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const createCreditCode = /* GraphQL */ `
  mutation CreateCreditCode(
    $input: CreateCreditCodeInput!
    $condition: ModelCreditCodeConditionInput
  ) {
    createCreditCode(input: $input, condition: $condition) {
      id
      code
      denomination
      status
      expirationDate
      assignedToRequestId
      createdAt
      updatedAt
    }
  }
`;
export const updateCreditCode = /* GraphQL */ `
  mutation UpdateCreditCode(
    $input: UpdateCreditCodeInput!
    $condition: ModelCreditCodeConditionInput
  ) {
    updateCreditCode(input: $input, condition: $condition) {
      id
      code
      denomination
      status
      expirationDate
      assignedToRequestId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCreditCode = /* GraphQL */ `
  mutation DeleteCreditCode(
    $input: DeleteCreditCodeInput!
    $condition: ModelCreditCodeConditionInput
  ) {
    deleteCreditCode(input: $input, condition: $condition) {
      id
      code
      denomination
      status
      expirationDate
      assignedToRequestId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFundingRequest = /* GraphQL */ `
  mutation DeleteFundingRequest(
    $input: DeleteFundingRequestInput!
    $condition: ModelFundingRequestConditionInput
  ) {
    deleteFundingRequest(input: $input, condition: $condition) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      techId
      tech {
        id
        name
        createdAt
        updatedAt
      }
      industryId
      industry {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      partnerLeaderAlias
      ByLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      BySecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      sfdclink
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          expirationDate
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            techId
            tech {
              id
              name
              createdAt
              updatedAt
            }
            industryId
            industry {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            partnerLeaderAlias
            ByLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            BySecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            sfdclink
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const createPermittedGeo = /* GraphQL */ `
  mutation CreatePermittedGeo(
    $input: CreatePermittedGeoInput!
    $condition: ModelPermittedGeoConditionInput
  ) {
    createPermittedGeo(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePermittedGeo = /* GraphQL */ `
  mutation UpdatePermittedGeo(
    $input: UpdatePermittedGeoInput!
    $condition: ModelPermittedGeoConditionInput
  ) {
    updatePermittedGeo(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePermittedGeo = /* GraphQL */ `
  mutation DeletePermittedGeo(
    $input: DeletePermittedGeoInput!
    $condition: ModelPermittedGeoConditionInput
  ) {
    deletePermittedGeo(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPermittedIndustry = /* GraphQL */ `
  mutation CreatePermittedIndustry(
    $input: CreatePermittedIndustryInput!
    $condition: ModelPermittedIndustryConditionInput
  ) {
    createPermittedIndustry(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePermittedIndustry = /* GraphQL */ `
  mutation UpdatePermittedIndustry(
    $input: UpdatePermittedIndustryInput!
    $condition: ModelPermittedIndustryConditionInput
  ) {
    updatePermittedIndustry(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePermittedIndustry = /* GraphQL */ `
  mutation DeletePermittedIndustry(
    $input: DeletePermittedIndustryInput!
    $condition: ModelPermittedIndustryConditionInput
  ) {
    deletePermittedIndustry(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPermittedTech = /* GraphQL */ `
  mutation CreatePermittedTech(
    $input: CreatePermittedTechInput!
    $condition: ModelPermittedTechConditionInput
  ) {
    createPermittedTech(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePermittedTech = /* GraphQL */ `
  mutation UpdatePermittedTech(
    $input: UpdatePermittedTechInput!
    $condition: ModelPermittedTechConditionInput
  ) {
    updatePermittedTech(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePermittedTech = /* GraphQL */ `
  mutation DeletePermittedTech(
    $input: DeletePermittedTechInput!
    $condition: ModelPermittedTechConditionInput
  ) {
    deletePermittedTech(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createGlobalConfigItem = /* GraphQL */ `
  mutation CreateGlobalConfigItem(
    $input: CreateGlobalConfigItemInput!
    $condition: ModelGlobalConfigItemConditionInput
  ) {
    createGlobalConfigItem(input: $input, condition: $condition) {
      property
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalConfigItem = /* GraphQL */ `
  mutation UpdateGlobalConfigItem(
    $input: UpdateGlobalConfigItemInput!
    $condition: ModelGlobalConfigItemConditionInput
  ) {
    updateGlobalConfigItem(input: $input, condition: $condition) {
      property
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalConfigItem = /* GraphQL */ `
  mutation DeleteGlobalConfigItem(
    $input: DeleteGlobalConfigItemInput!
    $condition: ModelGlobalConfigItemConditionInput
  ) {
    deleteGlobalConfigItem(input: $input, condition: $condition) {
      property
      value
      createdAt
      updatedAt
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      alias
      fullName
      email
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      alias
      fullName
      email
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      alias
      fullName
      email
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests {
        items {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            partnerSecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            allocatedBudget
            consumedBudget
            fundingRequests {
              nextToken
            }
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          techId
          tech {
            id
            name
            createdAt
            updatedAt
          }
          industryId
          industry {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          partnerLeaderAlias
          ByLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          BySecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          sfdclink
          description
          assignedCreditCodes {
            items {
              id
              code
              denomination
              status
              expirationDate
              assignedToRequestId
              createdAt
              updatedAt
            }
            nextToken
          }
          discussionThread {
            items {
              id
              fromAlias
              type
              createdAt
              fundingRequestId
              actionType
              modifiedProp
              newVal
              content
              associatedWithAction
            }
            nextToken
          }
          reportedOutcomes
          submittedAt
          lastUpdated
          alwaysSeven
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests {
        items {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            partnerSecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            allocatedBudget
            consumedBudget
            fundingRequests {
              nextToken
            }
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          techId
          tech {
            id
            name
            createdAt
            updatedAt
          }
          industryId
          industry {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          partnerLeaderAlias
          ByLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          BySecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          sfdclink
          description
          assignedCreditCodes {
            items {
              id
              code
              denomination
              status
              expirationDate
              assignedToRequestId
              createdAt
              updatedAt
            }
            nextToken
          }
          discussionThread {
            items {
              id
              fromAlias
              type
              createdAt
              fundingRequestId
              actionType
              modifiedProp
              newVal
              content
              associatedWithAction
            }
            nextToken
          }
          reportedOutcomes
          submittedAt
          lastUpdated
          alwaysSeven
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests {
        items {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            partnerSecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            allocatedBudget
            consumedBudget
            fundingRequests {
              nextToken
            }
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          techId
          tech {
            id
            name
            createdAt
            updatedAt
          }
          industryId
          industry {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          partnerLeaderAlias
          ByLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          BySecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          sfdclink
          description
          assignedCreditCodes {
            items {
              id
              code
              denomination
              status
              expirationDate
              assignedToRequestId
              createdAt
              updatedAt
            }
            nextToken
          }
          discussionThread {
            items {
              id
              fromAlias
              type
              createdAt
              fundingRequestId
              actionType
              modifiedProp
              newVal
              content
              associatedWithAction
            }
            nextToken
          }
          reportedOutcomes
          submittedAt
          lastUpdated
          alwaysSeven
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createThreadItem = /* GraphQL */ `
  mutation CreateThreadItem(
    $input: CreateThreadItemInput!
    $condition: ModelThreadItemConditionInput
  ) {
    createThreadItem(input: $input, condition: $condition) {
      id
      fromAlias
      from {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      type
      createdAt
      fundingRequestId
      fundingRequest {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          partnerSecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          allocatedBudget
          consumedBudget
          fundingRequests {
            items {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              techId
              industryId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              partnerLeaderAlias
              partnerSecondaryAlias
              totalRequested
              sfdclink
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        techId
        tech {
          id
          name
          createdAt
          updatedAt
        }
        industryId
        industry {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        partnerLeaderAlias
        ByLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        BySecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        sfdclink
        description
        assignedCreditCodes {
          items {
            id
            code
            denomination
            status
            expirationDate
            assignedToRequestId
            createdAt
            updatedAt
          }
          nextToken
        }
        discussionThread {
          items {
            id
            fromAlias
            from {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            type
            createdAt
            fundingRequestId
            fundingRequest {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              techId
              industryId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              partnerLeaderAlias
              partnerSecondaryAlias
              totalRequested
              sfdclink
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            actionType
            modifiedProp
            newVal
            content
            associatedWithAction
          }
          nextToken
        }
        reportedOutcomes
        submittedAt
        lastUpdated
        alwaysSeven
      }
      actionType
      modifiedProp
      newVal
      content
      associatedWithAction
    }
  }
`;
export const deleteThreadItem = /* GraphQL */ `
  mutation DeleteThreadItem(
    $input: DeleteThreadItemInput!
    $condition: ModelThreadItemConditionInput
  ) {
    deleteThreadItem(input: $input, condition: $condition) {
      id
      fromAlias
      from {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      type
      createdAt
      fundingRequestId
      fundingRequest {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          partnerSecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          allocatedBudget
          consumedBudget
          fundingRequests {
            items {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              techId
              industryId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              partnerLeaderAlias
              partnerSecondaryAlias
              totalRequested
              sfdclink
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        techId
        tech {
          id
          name
          createdAt
          updatedAt
        }
        industryId
        industry {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        partnerLeaderAlias
        ByLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        BySecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        sfdclink
        description
        assignedCreditCodes {
          items {
            id
            code
            denomination
            status
            expirationDate
            assignedToRequestId
            createdAt
            updatedAt
          }
          nextToken
        }
        discussionThread {
          items {
            id
            fromAlias
            from {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            type
            createdAt
            fundingRequestId
            fundingRequest {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              techId
              industryId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              partnerLeaderAlias
              partnerSecondaryAlias
              totalRequested
              sfdclink
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            actionType
            modifiedProp
            newVal
            content
            associatedWithAction
          }
          nextToken
        }
        reportedOutcomes
        submittedAt
        lastUpdated
        alwaysSeven
      }
      actionType
      modifiedProp
      newVal
      content
      associatedWithAction
    }
  }
`;
