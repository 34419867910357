import {Button, ColumnLayout, FormField, FormSection} from "@amzn/awsui-components-react";
import React from "react";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Icon from "@amzn/awsui-components-react/polaris/icon/icon";
import moment from "moment";


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default class UploadCreditCodesPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileError: null,
      fileLoading: false,
      selectedFile: null,
      fileData: null
    }

    this.promptForFile = this.promptForFile.bind(this)
    this.fileChange = this.fileChange.bind(this)
    this.readFile = this.readFile.bind(this)
  }

  promptForFile() {
    this.setState({fileError: null, fileData: null, selectedFile: null, fileLoading: false})
    const fileInput = document.getElementById("file_input")
    fileInput.value = ""
    fileInput.click()
  }

  bytesToString(bytes) {
    if (bytes < 1000) {
      return bytes + " bytes"
    } else if (bytes < (1000 * 1000)) {
      return (bytes / 1000).toFixed(1) + " KB"
    } else {
      return ((bytes / 1000) / 1000).toFixed(1) + " MB"
    }
  }

  fileChange() {
    const selectedFiles = document.getElementById("file_input").files
    if (selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0]
      if (selectedFile.type === "text/csv") {
        if (selectedFile.size < (1000 * 1000 * 3) /* 3 MB*/) {
          this.setState({
            fileError: null,
            fileLoading: true,
            selectedFile: {
              name: selectedFile.name,
              sizeStr: this.bytesToString(selectedFile.size),
              modifiedStr: moment(selectedFile.lastModified).fromNow(),
              filePtr: selectedFile,
              fileData: null
            }
          })
          this.readFile(selectedFile)
        } else {
          this.setState({
            fileError: "Selected file is too large (try splitting codes into batches)",
            selectedFile: null,
            fileLoading: false,
            fileData: null
          })
        }
      } else {
        this.setState({
          fileError: "Selected file is not of type 'text/csv'",
          selectedFile: null,
          fileLoading: false,
          fileData: null
        })
      }
    } else {
      this.setState({fileError: "Required field", selectedFile: null, fileLoading: false, fileData: null})
    }
  }

  async readFile(filePtr) {
    const result = await toBase64(filePtr).catch(e => Error(e));
    if (result instanceof Error) {
      console.log('Error reading csv file: ', result.message);
      this.setState({fileError: "Unknown error loading file: " + filePtr.name,
        fileLoading: false,
        selectedFile: null,
        fileData: null
      })
      return
    }
    this.setState({fileLoading: false, fileData: result})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.fileData !== this.state.fileData){
      if (this.props.onChange){
        this.props.onChange(this.state.fileData)
      }
    }
  }

  render() {
    return (
      <FormSection header={<h2>Step 2: Upload CSV File</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <div>
              <FormField
                hintText="CSV File must be less than 3MB, contain fewer than 40k rows, and have headers corresponding to the provided template"
                errorText={
                  this.state.fileError ?
                    this.state.fileError
                    :
                    (this.props.hasSubmitted && !this.state.fileData ? "Required field" : null)
                }
              >
                <input id="file_input" className="awsui-util-d-n" type="file" onChange={this.fileChange}/>
                <Button icon="upload" onClick={this.promptForFile}>Choose File</Button>
              </FormField>
            </div>
            {this.state.selectedFile && !this.state.fileError ?
              <div>
                <div className="awsui-util-d-ib awsui-util-pr-s align-top">
                  {this.state.fileLoading ?
                    <Spinner/>
                    :
                    <Icon name="status-positive" variant="success"/>
                  }
                </div>
                <div className="awsui-util-d-ib">
                  <span className="awsui-util-d-b">{this.state.selectedFile.name}</span>
                  <span className="awsui-util-d-b awsui-form-field-description">{this.state.selectedFile.sizeStr}</span>
                  <span
                    className="awsui-util-d-b awsui-form-field-description">Last modified: {this.state.selectedFile.modifiedStr}</span>
                </div>
              </div>
              :
              null
            }
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}