import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Logo from "../../assets/logon-logo-dark.png";

const useStyles = makeStyles({
  
  container: {
    marginTop: "250px",
    maxWidth: "550px",
    color: "##FC9614",
    borderRadius: 333
  },
  paper: {
    padding: "25px",
    backgroundColor : '#061b2e',
    borderRadius: 15
  },
  centered: {
    textAlign: "center",
  },
  title: {
    marginTop: "20px",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "30px",
    color: "##FC9614"
  },
  button: {
    size: "medium"
  },
});

export interface IProps {
  isLoading: boolean;
  onSignIn: () => void;
}

const Authentication: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const handleLogon = () => {
    props.onSignIn();
  };

  return (
    <Container 
    className={classes.container}>
      <Paper elevation={1} className={classes.paper}>
        <div className={classes.centered}>
          <img src={Logo} width={300 } height={200} alt="" />
        </div>
        <div>
          <Typography
           style={{fontSize: "30px",color: "#FFFFFF",fontFamily: "sans-serif"}}
            variant="h3"
            align="center"
            color="##FC9614"
            paragraph
            className={classes.title}
          >
            GSI BEE FUNDING
          </Typography>
        </div>
        {!props.isLoading && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              style={{
                borderRadius: 5,
                backgroundColor: "#FC9614",
                padding: "13px 36px",
                fontSize: "18px"
            }}
              color="primary"
              size="large"
              className={classes.button}
              onClick={() => handleLogon()}
            >
              Sign In with Federate
            </Button>
          </div>
        )}
        {props.isLoading && (
          <div className={classes.centered}>
            <CircularProgress color="primary" />
          </div>
        )}
      </Paper>
    </Container>
  );
};

export default Authentication;