/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://gyuyw6tybrcvtlwzcqrh53uhhu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "quicksight",
            "endpoint": "https://nzldpitae5.execute-api.us-west-2.amazonaws.com/uat",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:211cf2cf-2a05-4da9-b215-0be5700d1216",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_gPdmO8Bz7",
    "aws_user_pools_web_client_id": "1h7glm30j20rj2dpsk417agjnm",
    "oauth": {},
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
