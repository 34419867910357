import React from "react";
import {Alert, ColumnLayout, ExpandableSection, FormField, FormSection} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import {getDenominationsForTotal, getGlobalConfigItem, isDenominationSetAvailable} from "../../graphql/autogen/queries";
import DenominationSelector from "../FormComponents/DenominationSelector";
import ValidatedInput from "../FormComponents/ValidatedInput";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const FALLBACK_MAX_FUNDING_AMOUNT = 5000

class FundingTotalInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      return [false, "Required field"]
    }

    const numeric = Number(fieldText)
    if (isNaN(numeric)) {
      return [false, "Not a number"]
    }

    if (numeric < 25) {
      return [false, "Must be >$25"]
    }

    if (numeric > this.props.maximumFundingAmount) {
      return [false, "Individual funding requests must be <$" + this.props.maximumFundingAmount]
    }

    if (numeric % 25 !== 0) {
      return [false, "Must be a multiple of $25"]
    }

    return [true, ""]
  }
}


class DenominationBreakDownAlert extends React.Component {
  render() {
    if (this.props.autoGeneratedLoading){
      return (
        <div><Spinner/> Loading denomination availability</div>
      )
    }

    if (this.props.autoGeneratedLoadingError !== '') {
      return (
        <Alert type="error" header="Error Loading Credit Code Availability">
          {this.props.autoGeneratedLoadingError}
        </Alert>
      )
    }

    let denominations = this.props.requested
    if (denominations === null) {
      denominations = this.props.autoGenerated
    }
    if (denominations !== null && denominations.denominations.length > 0) {
      const denominationList = <div>
        Your request will be fulfilled with credit codes of the following denominations:
        <ul className={"tight"}>
          {
            denominations.denominations.map((denomination, i) => {
              return <li key={i}>{denomination.quantity}x ${denomination.value}</li>
            })
          }
        </ul>
      </div>;

      const ifYouHaveAPreference = "If you have a preference, please be sure to specify the denominations you would " +
        "like the credit codes in."

      const unavailabilityDisclaimer =
        <p>{this.props.requested !== null && this.props.specifyDenominationsExpanded === false ? "However, at" : "At"} the
          current time, there
          are insufficient codes available to fulfill this
          request. You may still submit it, but there may be some delay in getting your request approved while
          new codes are added. {this.props.requested === null ? ifYouHaveAPreference : null}</p>

      if (this.props.specifyDenominationsExpanded === false) {
        return (
          <Alert {...this.props}
                 type={denominations.isAvailable ? "info" : "warning"}
                 header={denominations.isAvailable ? "Denomination Details" : "Credit Codes Unavailable"}
                 buttonText="Modify Denominations">
            {denominations.isAvailable || this.props.requested !== null ? denominationList : null}
            {denominations.isAvailable ? null : unavailabilityDisclaimer}
          </Alert>
        );
      } else {
        if (!denominations.isAvailable) {
          return (
            <Alert {...this.props}
                   type="warning"
                   header="Credit Codes Unavailable">
              {denominations.isAvailable ? null : unavailabilityDisclaimer}
            </Alert>
          )
        }
      }
    }

    return null;
  }
}

class FundingPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalRequestedStr: this.props.initialState ? this.props.initialState.totalRequested.toString() : '',
      totalValid: !!this.props.initialState,
      loadingDenominations: false,
      autoGeneratedDenominations: null,
      loadingDenominationError: '',
      requestedDenominations: null,
      denominationsValid: true,
      specifyDenominationsExpanded: !!this.props.initialState && this.props.initialState.requestedDenominations !== null,
      maximumFundingAmount: FALLBACK_MAX_FUNDING_AMOUNT
    }

    this.fetchDenominations = this.fetchDenominations.bind(this)
    this.fetchMaximumFundingAmount = this.fetchMaximumFundingAmount.bind(this)
    this.pushChanges = this.pushChanges.bind(this)
  }

  componentDidMount() {
    if (this.props.initialState && !this.props.initialState.requestedDenominations){
      this.fetchDenominations(this.state.totalRequestedStr)
    }
    this.fetchMaximumFundingAmount()
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {
          totalRequested: this.state.totalValid ? Number(this.state.totalRequestedStr) : null,
          requestedDenominations: this.state.requestedDenominations ? this.state.requestedDenominations.denominations : null
        },
        valid: this.state.totalValid && this.state.denominationsValid
      })
    }
  }

  fetchDenominations(total) {
    if (total === null) {
      this.setState({autoGeneratedDenominations: null})
      return
    }

    this.setState({loadingDenominations: true, loadingDenominationError: ''})
    API.graphql(graphqlOperation(getDenominationsForTotal, {'total': Number(total)})).then(raw_resp => {
      const resp = raw_resp.data.getDenominationsForTotal;
      this.setState({autoGeneratedDenominations: resp})
    }).catch((e) => {
      console.log("Error fetching denominations: ", e)
      this.setState({loadingDenominationError: e.errors ? e.errors[0].message : e})
    }).finally(() => {
      this.setState({loadingDenominations: false})
    })
  }

  async fetchRequestedDenominationAvailability(requestedDenominations) {
    try {
      this.setState({loadingDenominations: true, loadingDenominationError: ''})
      const resp = await API.graphql(graphqlOperation(isDenominationSetAvailable, {denominations: requestedDenominations}))
      this.setState({requestedDenominations: resp.data.isDenominationSetAvailable})
    } catch (e) {
      console.log("Error fetching denominations: ", e)
      this.setState({requestedDenominations: null, loadingDenominationError: e.errors ? e.errors[0].message : e})
    } finally {
      this.setState({loadingDenominations: false})
    }
  }

  async fetchMaximumFundingAmount(){
    try {
      const resp = await API.graphql(graphqlOperation(
        getGlobalConfigItem, {property: "FundingRequestSingleRequestLimit"}
      ))
      this.setState({maximumFundingAmount: resp.data.getGlobalConfigItem.value})
    } catch (e) {
      console.log("Error fetching configuration item 'FundingRequestSingleRequestLimit': ", e)
    }
  }

  render() {  
    return (
      <FormSection header={<h2>Funding Details</h2>}
                   footer={<ExpandableSpecifyDenominations
                     disabled={this.props.initialState && this.props.initialState.status !== "PendingApproval"}
                     initialState={this.props.initialState ?
                       this.props.initialState.requestedDenominations ?
                         this.props.initialState.requestedDenominations.map((denom) => Object.assign({}, denom))
                       :
                         null
                     :
                       null
                     }
                     autoGeneratedDenominations={this.state.autoGeneratedDenominations}
                     expanded={this.state.specifyDenominationsExpanded}
                     hasSubmitted={this.props.hasSubmitted}
                     onChange={e => {
                       if (e.detail.valid === false) {
                         this.setState({denominationsValid: false}, () => this.pushChanges())
                         return
                       }

                       const total = e.detail.denominations.map(d => d.value * d.quantity).reduce((a, b) => a + b, 0)
                       this.setState({
                         totalRequestedStr: total.toString(),
                         denominationsValid: true
                       }, () => {
                         this.fetchRequestedDenominationAvailability(e.detail.denominations).then(() => {
                           this.pushChanges()
                         })
                       });
                     }}
                     onCollapse={(e) => this.setState({
                       specifyDenominationsExpanded: e.detail.expanded
                     })}
                   />
                   }>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>         
            <div id="b">
              <div id="c" className="awsui">
                <div className={"unauth-container"}>
                  <Alert header="Important" type="info">
                  Please add your Total Funding Requested first and denomination will be auto-calculated based on the availability of the codes.
                  You can change that by expanding Specify Denominations after that.
                  </Alert>
                </div>
              </div>
            </div>        
            <FormField
              label={"Total Funding Requested"}
              hintText={"Min: $25, Max: $" +  this.state.maximumFundingAmount + ". Must be a multiple of $25"}
              description={"The total value of credit codes requested for this project (USD)"}
              stretch={false}
            >                      
              <span className="awsui-util-pr-xs">$</span>
              <FundingTotalInput className={"custom-input-small"}
                                 type={"number"}
                                 required={true}
                                 maximumFundingAmount={this.state.maximumFundingAmount}
                                 hasSubmitted={this.props.hasSubmitted}
                                 value={this.state.totalRequestedStr}
                                 disabled={
                                   this.state.specifyDenominationsExpanded
                                   || (!!this.props.initialState && this.props.initialState.status !== "PendingApproval")
                                 }
                                 onInput={e => {
                                   this.setState({
                                     totalRequestedStr: e.detail.value,
                                     totalValid: e.detail.valid,
                                     requestedDenominations: null
                                   }, () => this.pushChanges())
                                   if (e.detail.valid) {
                                     this.fetchDenominations(e.detail.value)
                                   } else {
                                     this.fetchDenominations(null)
                                   }
                                 }}/>
            </FormField>
            <DenominationBreakDownAlert specifyDenominationsExpanded={this.state.specifyDenominationsExpanded}
                                        autoGenerated={this.state.autoGeneratedDenominations}
                                        autoGeneratedLoading={this.state.loadingDenominations}
                                        autoGeneratedLoadingError={this.state.loadingDenominationError}
                                        requested={this.state.requestedDenominations}
                                        onButtonClick={() => this.setState({specifyDenominationsExpanded: true})}/>
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

class ExpandableSpecifyDenominations extends React.Component {
  render() {    
    return (
      
      <ExpandableSection
        onChange={this.props.onCollapse}
        expanded={this.props.expanded}
        header="Specify Denominations"
        variant="borderless"
      >
        <ColumnLayout>
         <div data-awsui-column-layout-root={true}>
        
            <DenominationSelector
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              hasSubmitted={this.props.hasSubmitted}
              autoGeneratedDenominations={
                this.props.autoGeneratedDenominations ?
                  this.props.autoGeneratedDenominations.denominations
                :
                  this.props.initialState
              }
            />            
          </div>
        </ColumnLayout>
      </ExpandableSection>
      
    );
  }
}

export default FundingPanel