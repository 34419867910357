import React from "react";

import {Button, Form} from '@amzn/awsui-components-react';
import {withRouter} from "react-router-dom";
import UploadCreditCodesPanel from "./UploadCreditCodesPanel";
import {API, graphqlOperation} from "aws-amplify";
import {importCreditCodes} from "../../graphql/autogen/mutations";
import DownloadTemplatePanel from "./DownloadTemplatePanel";

class ImportCreditCodesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrorText: "",
      hasSubmitted: false,
      submitLoading: false,
      fileData: null
    }

    this.submitCallback = this.submitCallback.bind(this)
  }

  async submitCallback(){
    this.setState({hasSubmitted: true})
    if (this.state.fileData !== null){
      this.setState({submitLoading: true})
      try {
        await API.graphql(graphqlOperation(importCreditCodes, {dataURL: this.state.fileData}))
        this.props.history.push(`/CreditCode/list?message=importPending`)
      } catch (error) {
        console.log("Error importing credit codes: ", error)
        let errString = error.errors.map(er => er.errorType + ": " + er.message).join("; \n")
        this.setState({formErrorText: errString})
      }
    }
    this.setState({submitLoading: false})
  }

  render() {
    const { hasSubmitted } = this.state
    return (
      <Form
        header={<h1>Import Credit Codes</h1>}
        errorText={this.state.formErrorText}
        actions={
          // located at the bottom of the form
          <div>
            <Button variant="link" href="#/CreditCode/List">Cancel</Button>
            <Button onClick={this.submitCallback}
                    loading={this.state.submitLoading}
                    variant="primary">
              Import Codes
            </Button>
          </div>
        }
      >
        <DownloadTemplatePanel/>
        <UploadCreditCodesPanel onChange={(fileData) => this.setState({fileData})} hasSubmitted={hasSubmitted}/>
      </Form>
    )
  }
}

export default withRouter(ImportCreditCodesForm);