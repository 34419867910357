import {FormSection} from "@amzn/awsui-components-react";
import React from "react";
import screenshot from '../../assets/code_import_screenshot.png'


export default class DownloadTemplatePanel extends React.Component {
  render() {
    return (
      <FormSection header={<h2>Step 1: Download CSV Template</h2>}>
        <p>
          Use <a href={'/credit_code_import_template.csv'}>this template</a> to create a CSV file containing the credit codes to import.
        </p>
        <p>
          Alternatively, create a UTF-8 encoded CSV file with exactly two columns: 'value', 'code' , 'expirationDate' (mm/dd/yy format) (case
          insensitive). Ensure that every provided row has both a code and value defined and that all entries in the
          value column are positive integers with no currency prefix.
        </p>
        <p>
          For example:
        </p>
        <img src={screenshot} alt="A screenshot of excel showing an example CSV file containing credit codes." width="200px"/>
      </FormSection>
    )
  }
}